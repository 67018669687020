import React from 'react';
import Linkify from 'linkify-react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Link, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import useInactivity from 'hooks/useInactivity';
import { useDispatch, useSelector } from 'hooks';
import useLabelTranslation from 'hooks/useLabelTranslation';
import useAnalytics from 'hooks/useAnalytics';

import { logout } from 'modules/auth/actions';
import { getParkingInfo } from 'modules/selfServices/selectors';
import { getSelectedSelfServiceId } from 'modules/auth/selectors';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';
import { getSelfServiceType, hasClickableInstruction, isPublicDevice } from 'modules/dealers/selectors';
import {
  useGetFinalInstructionsQuery,
  useGetSelfServiceByIdQuery,
  useGetSelfServicePdfMutation,
} from 'modules/selfServices/service';

import { ButtonType } from 'components/ui/Button';
import {
  Button,
  Carousel,
  Attachments,
  Spinner as Loader,
} from 'components/ui';

import { getVehicleCheckType } from 'modules/vehicleCheck/selectors';
import { Modal } from '../ui';
import ParkingContent from '../Parking/ParkingContent';

const FinalInstructions: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { onClick } = useAnalytics();
  const { getLabelTranslation } = useLabelTranslation();
  const { selfServiceId: selfServiceIdParams } = useParams();

  const parking = useSelector(getParkingInfo);
  const type = useSelector(getSelfServiceType);
  const isPublic = useSelector(isPublicDevice);
  const vehicleCheckType = useSelector(getVehicleCheckType);
  const hasClickableDescription = useSelector(hasClickableInstruction);
  const selfServiceId = useSelector(getSelectedSelfServiceId) ?? selfServiceIdParams;

  const [isCarouselFullscreen, setCarouselFullscreen] = React.useState(false);
  const [isParkingModalOpen, setParkingModal] = React.useState(false);

  const { data } = useGetSelfServiceByIdQuery(selfServiceId ? { id: selfServiceId } : skipToken);
  const [downloadPdf, { isLoading: isDownloading }] = useGetSelfServicePdfMutation();
  const {
    data: instructions,
    isLoading,
    isSuccess,
  } = useGetFinalInstructionsQuery({
    selfServiceId,
  });

  const handleInactivity = React.useCallback(() => {
    if (isPublic) {
      dispatch(logout());
    }
  }, [dispatch, isPublic]);

  useInactivity({ seconds: 30, onInactive: handleInactivity });

  const isCheckout = type === SelfServiceType.CHECK_OUT;
  const isCheckin = type === SelfServiceType.CHECK_IN || vehicleCheckType === SelfServiceType.CHECK_IN;

  const displayDownloadSummary = !isPublic && isCheckin;
  const displayFinalInvoice = !isPublic && isCheckout && Boolean(data?.finalInvoice);
  const displayParkingLocation = Boolean(parking?.spotNumber || parking?.x >= 0 || parking?.y >= 0) && isCheckout;
  const displayExternalKioskLink = Boolean(instructions?.externalKioskLink);

  const Instruction = React.useMemo(
    () => (hasClickableDescription ? Linkify : React.Fragment),
    [hasClickableDescription],
  );
  const intructionProps = React.useMemo( // This avoids giving `options` to a Fragment, which causes a warning
    () => (hasClickableDescription ? { options: { target: { url: '_blank' } } } : {}),
    [hasClickableDescription],
  );

  let finalInstructionMessage;
  if (isSuccess && instructions?.message) {
    finalInstructionMessage = getLabelTranslation(instructions.message);
  }

  const handleShowParkingButton = React.useCallback(() => setParkingModal(true), []);

  const handleDownload = React.useCallback(() => {
    onClick(
      { action: 'DownloadFile', category: 'FINAL_INSTRUCTIONS' },
      downloadPdf,
    )({
      id: selfServiceId,
      filename: `${intl.formatMessage({
        id: 'page.finalInstructions.downloadPdf.filename',
        defaultMessage: 'Check-in summary',
      })}.pdf`,
    });
  }, [downloadPdf, intl, selfServiceId, onClick]);

  const handleCarouselOpen = React.useCallback(() => setCarouselFullscreen(true), []);
  const handleCarouselClose = React.useCallback(() => setCarouselFullscreen(false), []);

  return (
    <>
      {isLoading && <Loader className="homePage" />}
      {isSuccess && (
        <div className="flex flex-col items-center">
          <div className="main-content kiosk:pt-24 pt-10">
            <div className="flex flex-col text-center w-full">
              <h1 className="text-gray-90">
                <FormattedMessage
                  id="page.finalInstructions.title"
                  defaultMessage="Thank you"
                />
              </h1>
              <p className="w-full text-gray-70 mb-6 mt-5 whitespace-pre-wrap break-words">
                <Instruction {...intructionProps}>
                  {finalInstructionMessage}
                </Instruction>
              </p>
            </div>
            <div className="flex gap-4 flex-col rounded-xl overflow-hidden w-full">
              {instructions?.medias?.length > 0 && (
                <Carousel
                  medias={instructions.medias}
                  onClose={handleCarouselClose}
                  fullscreen={isCarouselFullscreen}
                  onMediaClick={handleCarouselOpen}
                />
              )}
            </div>
            <div className="flex flex-col items-center w-full">
              {displayExternalKioskLink && (
                <div className="w-5/6 mb-5 text-center">
                  <Link to={instructions.externalKioskLink} target="_blank">
                    <Button className="w-full max-w-lg">
                      <FormattedMessage
                        id="page.finalInstructions.externalLinkButton"
                        defaultMessage="Click here to open the cabinet"
                      />
                    </Button>
                  </Link>
                </div>
              )}
              {displayParkingLocation && (
                <>
                  <div className="w-5/6 mb-5 text-center">
                    <Button
                      type={ButtonType.SECONDARY}
                      onClick={handleShowParkingButton}
                      testId="showParking"
                      className="w-full max-w-lg"
                    >
                      <FormattedMessage
                        id="page.finalInstructions.parking"
                        defaultMessage="Show my parking location"
                      />
                    </Button>
                  </div>
                  <Modal
                    open={isParkingModalOpen}
                    onClose={setParkingModal}
                    className="w-full max-w-lg kiosk:max-w-4xl"
                  >
                    <div className="content pb-0">
                      <ParkingContent hideInstructions maps={data?.parkingMap?.maps} {...data?.parking} />
                    </div>
                  </Modal>
                </>
              )}
              {displayDownloadSummary && (
                <div className="w-5/6 mb-5 text-center">
                  <Button
                    type={displayExternalKioskLink ? ButtonType.SECONDARY : ButtonType.TERTIARY}
                    onClick={handleDownload}
                    loading={isDownloading}
                    disabled={isDownloading}
                    testId="downloadSummary"
                    className="w-full max-w-lg"
                  >
                    <FormattedMessage
                      id="page.finalInstructions.button"
                      defaultMessage="Download summary (PDF)"
                    />
                  </Button>
                </div>
              )}
              {displayFinalInvoice && (
                <Attachments
                  documents={data.finalInvoice.medias}
                  className="w-full"
                  buttonProps={{
                    className: 'w-5/6 max-w-lg mb-5',
                    type: ButtonType.TERTIARY,
                  }}
                  singleDownloadTitle={(
                    <FormattedMessage
                      id="page.finalInvoice.buttons.download"
                      defaultMessage="Download invoice"
                    />
                  )}
                />
              )}
              {isPublic && (
                <div className="w-5/6 mb-5 text-center">
                  <Button
                    onClick={handleInactivity}
                    className="w-full max-w-lg"
                    testId="goHome"
                  >
                    <FormattedMessage id="quit" defaultMessage="Quit" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FinalInstructions;
