import { createReducer, isAnyOf } from '@reduxjs/toolkit';

import authApi from 'modules/auth/service';
import kioskApi from 'modules/kiosk/service';
import { logout } from 'modules/auth/actions';
import { setIsKioskReady } from 'modules/kiosk/actions';

interface InitialState { isPaymentModalVisible: boolean; isKioskReady: boolean; readySearchText?: string; }

const initialState: InitialState = {
  isKioskReady: false,
  isPaymentModalVisible: false,
  readySearchText: null,
};

const kioskReducer = createReducer<InitialState>(initialState, (builder) => {
  builder
    .addCase(logout, () => initialState)
    .addCase(setIsKioskReady, (state, { payload }) => ({ ...state, isKioskReady: payload }))
    .addMatcher(kioskApi.endpoints.getReadySelfServices.matchPending, (state) => ({ ...state, readySearchText: null }))
    .addMatcher(
      isAnyOf(authApi.endpoints.loginMFA.matchFulfilled, authApi.endpoints.loginAdmin.matchFulfilled),
      (state, { payload }) => ({ ...state, isKioskReady: payload?.kioskPrepared ?? false }),
    )
    .addMatcher(
      kioskApi.endpoints.getReadySelfServices.matchFulfilled,
      (state, { meta }) => ({ ...state, readySearchText: meta.arg.originalArgs.text }),
    );
});

export default kioskReducer;
