import Message from 'modules/chat/types/Message';

export enum SocketResponseAction {
  INTERVENTION_UPDATE = 'INTERVENTION_UPDATE',
  KIOSK_PAYMENT_UPDATE = 'KIOSK_PAYMENT_UPDATE',
  KIOSK_OPERATION_UPDATE = 'KIOSK_OPERATION_UPDATE',
  CONVERSATION_MESSAGE_CREATED = 'CONVERSATION_MESSAGE_CREATED',
}

export interface ResponseData {
  id: string;
  eventId: string;
  checkOutCompleted: boolean;
  updatedMedia: boolean;
  updatedExtras: boolean;
  updatedComment: boolean;
  updatedParking: boolean;
  updatedPayment: boolean;
  updatedWorksDone: boolean;
  paymentInProgress: boolean;
  updatedQuestionForm: boolean;
  updatedFinalInvoice: boolean;
  vehicleCheckStarted: boolean;
  updatedInterventionMedias: boolean;
}

export interface SocketResponseUpdate<T = ResponseData> {
  action: SocketResponseAction;
  createdOn: string;
  entityId: string;
  data: T;
}

export type SocketResponse = SocketResponseUpdate | { status: number } | string;

// eslint-disable-next-line max-len
export const isSocketResponseUpdate = (response: SocketResponse): response is SocketResponseUpdate => (response as SocketResponseUpdate).action !== undefined;

export interface KioskResponse<T> extends SocketResponseUpdate<T> {
  isSuccess: boolean;
}

export type KioskPaymentUpdate = KioskResponse<{
  canceled: boolean;
  refused: boolean;
  timeout: boolean; // true if the lambda timeout after a while (100s or 300s without interaction)
  providerError: boolean; // true for the rest of the errors coming from the provider
  hostUnavailable: boolean; // true if the provider is in error and have the specific error of the host unavailable
}>;

export type KioskOperationUpdate = KioskResponse<{
  doorClosed: boolean;
  doorCloseFailed: boolean;
  doorOpened: boolean;
  doorOpenedFailed: boolean;
  doorPrepared: boolean;
  doorPreparedFailed: boolean;
}>;

export type IncomingMessage = KioskResponse<{
  message: Message
}>;
