/* eslint-disable no-await-in-loop */
import { addDays, differenceInMilliseconds, startOfDay } from 'date-fns';
import { TakePattern } from '@reduxjs/toolkit/dist/listenerMiddleware/types';
import {
  createListenerMiddleware, ThunkDispatch, UnknownAction,
} from '@reduxjs/toolkit';

import { locationChange } from 'modules/router/actions';

import appApi from 'modules/app/service';
import dealersApi from 'modules/dealers/service';

import { RootState } from 'App/rootReducer';
import { AppStartListening } from 'App/ListenerMiddleware';

import { isHomePage } from 'modules/router/selectors';
import { isKioskEmergencyMode, isPublicOnSite } from 'modules/dealers/selectors';

import packageJson from '../../../package.json';

const EMERGENCY_REFRESH_DELAY = 60 * 1000; // 1 minute

const { version: CURRENT_VERSION } = packageJson;

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

const delay = (ms: number) => new Promise((resolve) => { setTimeout(resolve, ms); });

const reload = () => window.location.reload();

const checkVersion = async (
  getState: () => RootState,
  dispatch: ThunkDispatch<RootState, undefined, UnknownAction>,
  take: TakePattern<RootState>,
) => {
  const now = Date.now();

  // Wait for midnight
  const nextDay = startOfDay(addDays(now, 1));
  const initialDelay = differenceInMilliseconds(nextDay, now);
  await delay(initialDelay);

  // Fetch meta.json in app files
  let hasFetched = false;
  let shouldUpdate = false;
  do {
    try {
      const { version } = await dispatch(appApi.endpoints.getVersion.initiate()).unwrap();
      shouldUpdate = CURRENT_VERSION !== version;
      hasFetched = true;
    } catch (error) {
      await delay(60000);
    }
  } while (!hasFetched);

  // Wait for user to change its' location to the homepage
  let isHome = isHomePage(getState());
  while (!isHome) {
    await take(locationChange.match);
    isHome = isHomePage(getState());
  }

  // If version is different and we are on homepage and it's midnight or more, we reload
  if (shouldUpdate) {
    reload();

    // If, for whatever reason, the reload fails, we will try again when user interacts with the page
    const options = { capture: true, once: true };
    window.addEventListener('visibilitychange', reload, options);
    window.addEventListener('keydown', reload, options);
    window.addEventListener('pointermove', reload, options);
  } else {
    // No need for updates, we start the timer and process once again
    await checkVersion(getState, dispatch, take);
  }
};

startAppListening({
  matcher: dealersApi.endpoints.getContext.matchFulfilled,
  effect: async (_, {
    dispatch, cancelActiveListeners, getState, take,
  }) => {
    await cancelActiveListeners();

    const isPublic = isPublicOnSite(getState());
    if (isPublic) {
      checkVersion(getState, dispatch, take);
    }

    if (isKioskEmergencyMode(getState())) {
      await delay(EMERGENCY_REFRESH_DELAY);
      await dispatch(dealersApi.endpoints.getContext.initiate(undefined, { forceRefetch: true })).unwrap();
    }
  },
});

startAppListening({
  matcher: locationChange.match,
  effect: (_, { dispatch, getState }) => {
    if (isHomePage(getState())) {
      dispatch(dealersApi.endpoints.getContext.initiate(undefined, { forceRefetch: true })).unwrap();
    }
  },
});

export default listenerMiddleware;
