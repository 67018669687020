import React from 'react';
import Bowser from 'bowser';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Props as MessageProps } from 'react-intl/src/components/message';
import {
  InformationCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, CheckCircleIcon,
} from '@heroicons/react/24/solid';

import {
  NotificationType, Collapse, isIntlMessage,
} from 'modules/notifications/types/Notification';

import Arrow from 'assets/icons/arrow.svg';
import ArrowDirection from 'assets/icons/Arrow';
import { shouldDisplayTransitions } from 'modules/app/selectors';

import { useSelector } from '../../hooks';

interface AlertProps {
  title?: MessageProps | React.ReactNode;
  description?: MessageProps | React.ReactNode;
  collapse?: Collapse;
  duration?: number;
  type?: NotificationType;
  onClose?: () => void;
}

const ALERT_CONFIG = {
  [NotificationType.INFO]: {
    className: 'bg-blue-50 text-blue-700',
    icon: <InformationCircleIcon className="h-5 w-5 text-blue-400" />,
  },
  [NotificationType.ERROR]: {
    className: 'bg-red-50 text-red-700',
    icon: <ExclamationCircleIcon className="h-5 w-5 text-red-400" />,
  },
  [NotificationType.WARNING]: {
    className: 'bg-yellow-50 text-yellow-700',
    icon: <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />,
  },
  [NotificationType.SUCCESS]: {
    className: 'bg-green-50 text-green-700',
    icon: <CheckCircleIcon className="h-5 w-5 text-green-400" />,
  },
};

const descriptionFormatter = ({ values = {}, ...descriptor }) => ({
  ...descriptor,
  values: Object.entries(values).reduce((acc, [field, value]) => {
    const formattedValue = typeof value === 'string' && Date.parse(value) ? (
      <FormattedDate value={value} year="numeric" month="short" day="numeric" />
    ) : (
      value
    );

    return {
      ...acc,
      [field]: formattedValue,
    };
  }, {}),
});

const transitions = {
  enterTo: 'translate-x-0',
  leaveFrom: 'translate-x-0',
  enterFrom: 'translate-x-full',
  leaveTo: 'translate-x-full',
  enter: 'transition-all duration-300',
  leave: 'transition-all duration-300',
};

const Alert: React.FC<AlertProps> = ({
  title, description, collapse, duration, type = NotificationType.INFO, onClose,
}) => {
  const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName();
  const platformType = Bowser.getParser(window.navigator.userAgent).getPlatformType();
  const version = Bowser.getParser(window.navigator.userAgent).getBrowserVersion()?.split('.')?.[0];

  const displayTransitions = useSelector(shouldDisplayTransitions);

  const [isExpanded, setIsExpanded] = React.useState(false);
  const timeout = React.useRef<NodeJS.Timeout>(null);

  const [show, setShow] = React.useState(true);

  const toggleExpand = React.useCallback(() => setIsExpanded((oldIsExpanded) => !oldIsExpanded), []);

  React.useLayoutEffect(() => {
    if (duration) {
      timeout.current = setTimeout(() => setShow(false), duration);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);

        // Safari 13+ doesn't support afterLeave props on Transition component
        if (platformType === 'mobile' && browserName === 'Safari' && parseInt(version, 10) < 14) {
          setTimeout(() => onClose?.(), duration + 300);
        }
      }
    };
  }, [duration, onClose]);

  return (
    <Transition
      show={show}
      appear
      afterLeave={onClose}
      {...(displayTransitions ? transitions : {})}
      className={classNames(
        'w-72 kiosk:w-96 min-h-10 z-50 p-2 pl-0 flex rounded-lg shadow-md mb-4',
        ALERT_CONFIG[type].className,
      )}
    >
      <div className="flex justify-center mt-1 w-1/6">
        {ALERT_CONFIG[type].icon}
      </div>
      <div className="w-5/6">
        {title && (
          <h5 className="font-bold kiosk:text-xl">
            {isIntlMessage(title) ? <FormattedMessage {...title} /> : title}
          </h5>
        )}
        {description && (
          <p className="text-xs kiosk:text-base">
            {isIntlMessage(description)
              ? <FormattedMessage {...descriptionFormatter(description)} />
              : description}
          </p>
        )}
        {collapse && (
          <>
            <div className="flex items-center cursor-pointer" onClick={toggleExpand}>
              <Arrow className={classNames(
                'size-3 mr-2 fill-red-700',
                isExpanded ? ArrowDirection.UP : ArrowDirection.DOWN,
              )}
              />
              <span className="text-xs kiosk:text-base">
                {isIntlMessage(collapse.title) ? <FormattedMessage {...collapse.title} /> : collapse.title}
              </span>
            </div>
            {isExpanded && (
              <p className="text-xs kiosk:text-base">
                {isIntlMessage(collapse.description)
                  ? (<FormattedMessage {...collapse.description} />)
                  : (collapse.description)}
              </p>
            )}
          </>
        )}
      </div>
    </Transition>
  );
};

export default Alert;
