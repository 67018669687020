import React, { ReactSVGElement } from 'react';
import { DocumentIcon } from '@heroicons/react/24/outline';

import { AbstractMedia } from 'types/AbstractMedia';

import AVI from 'assets/icons/fileType/avi.svg';
import GIF from 'assets/icons/fileType/gif.svg';
import JPG from 'assets/icons/fileType/jpg.svg';
import MOV from 'assets/icons/fileType/mov.svg';
import MP3 from 'assets/icons/fileType/mp3.svg';
import MP4 from 'assets/icons/fileType/mp4.svg';
import PNG from 'assets/icons/fileType/png.svg';
import RAR from 'assets/icons/fileType/rar.svg';
import ROW from 'assets/icons/fileType/row.svg';
import WAV from 'assets/icons/fileType/wav.svg';
import XLS from 'assets/icons/fileType/xls.svg';
import PPT from 'assets/icons/fileType/ppt.svg';
import ZIP from 'assets/icons/fileType/zip.svg';
import TXT from 'assets/icons/fileType/txt.svg';
import PDF from 'assets/icons/fileType/pdf.svg';
import WORD from 'assets/icons/fileType/word.svg';

export const extensionToComponent: Record<string, React.FC<React.SVGProps<React.ReactSVGElement>>> = {
  avi: AVI,
  gif: GIF,
  jpg: JPG,
  jpeg: JPG,
  mov: MOV,
  mp3: MP3,
  mp4: MP4,
  png: PNG,
  rar: RAR,
  row: ROW,
  wav: WAV,
  xls: XLS,
  xlsx: XLS,
  csv: XLS,
  doc: WORD,
  docx: WORD,
  ppt: PPT,
  pptx: PPT,
  zip: ZIP,
  txt: TXT,
  pdf: PDF,
};

const FileTypeIcon: React.FC<Pick<AbstractMedia, 'filename'>> = ({ filename }) => {
  const extension = filename.split('.').pop();
  const Icon = extensionToComponent[extension.toLocaleLowerCase()];

  return (
    <div className="bg-white size-full pt-3 pb-2 px-1 flex flex-col justify-between items-center">
      {Icon && <Icon className="h-1/2" />}
      {!Icon && <DocumentIcon className="flex-none h-1/2" />}
      <span className="text-gray-70 text-xs sm:text-base kiosk:text-3xl whitespace-nowrap overflow-hidden text-ellipsis max-w-full">
        {filename}
      </span>
    </div>
  );
};

export default FileTypeIcon;
