import React from 'react';
import classNames from 'classnames';

import { AbstractMedia, MediaType } from 'types/AbstractMedia';

interface BackgroundProps {
  media?: AbstractMedia
}

const MEDIA_CLASSNAMES = 'fixed object-cover w-screen h-screen';

const Background: React.FC<React.PropsWithChildren<BackgroundProps>> = ({ media, children }) => {
  const [forceDefaultBackground, setForceDefaultBackground] = React.useState(false);

  const isVideo = media?.type === MediaType.VIDEO;
  const isCustom = !forceDefaultBackground && Boolean(media);

  const handleError = React.useCallback(() => setForceDefaultBackground(true), []);

  React.useLayoutEffect(() => {
    // Reset the background if the media is changed
    if (media) {
      setForceDefaultBackground(false);
    }
  }, [media]);

  return (
    <div className="flex flex-col items-center justify-start bg-cover bg-center homePage bg-home -z-10">
      {isCustom && (
        <>
          {!isVideo && (
            <img
              alt=""
              loading="eager"
              src={media.path}
              // @ts-expect-error - See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#fetchpriority)
              // eslint-disable-next-line react/no-unknown-property
              fetchpriority="high"
              onError={handleError}
              className={MEDIA_CLASSNAMES}
              data-testid="background-image"
            />
          )}
          {isVideo && (
            <video
              loop
              muted
              autoPlay
              playsInline
              preload="auto"
              controls={false}
              onError={handleError}
              disableRemotePlayback
              disablePictureInPicture
              data-testid="background-video"
              className={classNames(MEDIA_CLASSNAMES, 'pointer-events-none')}
            >
              <source src={media.path} />
            </video>
          )}
        </>
      )}
      {children}
    </div>
  );
};

export default Background;
