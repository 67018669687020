// eslint-disable-next-line import/prefer-default-export
export enum SelfServiceSteps {
  BREAKDOWN_INFO = 'BREAKDOWN_INFO',
  COURTESY_VEHICLE = 'COURTESY_VEHICLE',
  CUSTOMER_INFO = 'CUSTOMER_INFO',
  EXTRAS = 'EXTRAS',
  FINAL_INSTRUCTIONS = 'FINAL_INSTRUCTIONS',
  FINAL_INVOICE = 'FINAL_INVOICE',
  KEY = 'KEY',
  KIOSK_LOCATION = 'KIOSK_LOCATION',
  KIOSK_USAGE = 'KIOSK_USAGE',
  MOBILITY_CONTRACT = 'MOBILITY_CONTRACT',
  MOBILITY_DOCUMENTS = 'MOBILITY_DOCUMENTS',
  MOBILITY_PARKING = 'MOBILITY_PARKING',
  PARKING = 'PARKING',
  QUESTIONS = 'QUESTIONS',
  REMOTE_KEY = 'REMOTE_KEY',
  REPAIR_ORDER = 'REPAIR_ORDER',
  SIGNATURE = 'SIGNATURE',
  SUMMARY = 'SUMMARY',
  VEHICLE_CHECK = 'VEHICLE_CHECK',
  VEHICLE_CHECK_QR_CODE = 'VEHICLE_CHECK_QR_CODE',
}
