import Parking from 'components/Parking';
import FinalInvoice from 'components/FinalInvoice';
import AppointmentSummary from 'components/AppointmentSummary';
import ParkingInstruction from 'components/Parking/ParkingInstruction';

import { SelfService, SelfServiceOrigin } from 'modules/selfServices/types/SelfService';

import { SOCKET_UPDATE_STEPS } from 'modules/sockets/constants';
import { SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';
import {
  KEY,
  REMOTE_KEY,
  hasParking,
  isNotAnswered,
  isVisibleStep,
  KIOSK_LOCATION,
  SelfServiceStep,
  FINAL_INSTRUCTIONS,
} from './common';

const SUMMARY: SelfServiceStep = {
  key: SelfServiceSteps.SUMMARY,
  component: AppointmentSummary,
  next: SelfServiceSteps.FINAL_INVOICE,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
    socketUpdateFields: SOCKET_UPDATE_STEPS.SUMMARY,
  },
};

const FINAL_INVOICE: SelfServiceStep = {
  key: SelfServiceSteps.FINAL_INVOICE,
  component: FinalInvoice,
  previous: SUMMARY.key,
  next: SelfServiceSteps.PARKING,
  data: {
    isVisible: ({ status, finalInvoice }) => isNotAnswered(status) && Boolean(finalInvoice),
    socketUpdateFields: SOCKET_UPDATE_STEPS.FINAL_INVOICE,
  },
};

const PARKING: SelfServiceStep = {
  key: SelfServiceSteps.PARKING,
  component: ParkingInstruction,
  previous: FINAL_INVOICE.key,
  data: {
    isVisible: ({ status, parking }) => isNotAnswered(status) && hasParking(parking),
    socketUpdateFields: SOCKET_UPDATE_STEPS.PARKING,
  },
};

const MOBILITY_PARKING: SelfServiceStep = {
  key: SelfServiceSteps.MOBILITY_PARKING,
  component: Parking,
  previous: PARKING.key,
  data: {
    isVisible: ({ status, mobilityEnabled, parkingMap }) => isNotAnswered(status)
      && mobilityEnabled && (parkingMap?.allowParkingSpot || parkingMap?.maps?.length > 0),
  },
};

const HOME_CHECK_OUT_STEPS: Record<string, SelfServiceStep> = {
  SUMMARY: {
    ...SUMMARY,
    data: { ...SUMMARY.data, isFinalizeStep: (selfService: SelfService) => !isVisibleStep(FINAL_INVOICE, selfService) },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...FINAL_INVOICE.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: FINAL_INVOICE.key },
};

const ON_SITE_CHECK_OUT_STEPS: Record<string, SelfServiceStep> = {
  SUMMARY: {
    ...SUMMARY,
    data: {
      ...SUMMARY.data,
      isFinalizeStep: (selfService: SelfService) => !isVisibleStep(FINAL_INVOICE, selfService) && !isVisibleStep(PARKING, selfService) && !isVisibleStep(MOBILITY_PARKING, selfService),
    },
  },
  FINAL_INVOICE: {
    ...FINAL_INVOICE,
    data: { ...FINAL_INVOICE.data, isFinalizeStep: (selfService: SelfService) => !isVisibleStep(PARKING, selfService) && !isVisibleStep(MOBILITY_PARKING, selfService) },
  },
  PARKING: {
    ...PARKING,
    next: MOBILITY_PARKING.key,
    data: {
      ...PARKING.data,
      isFinalizeStep: (selfService: SelfService) => !isVisibleStep(MOBILITY_PARKING, selfService),
    },
  },
  MOBILITY_PARKING: {
    ...MOBILITY_PARKING,
    next: FINAL_INSTRUCTIONS.key,
    data: {
      ...MOBILITY_PARKING.data,
      isFinalizeStep: true,
    },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: MOBILITY_PARKING.key },
};

const KIOSK_CHECK_OUT_STEPS: Record<string, SelfServiceStep> = {
  SUMMARY,
  FINAL_INVOICE,
  PARKING: { ...PARKING, next: MOBILITY_PARKING.key },
  MOBILITY_PARKING: { ...MOBILITY_PARKING, next: KIOSK_LOCATION.key },
  KIOSK_LOCATION: {
    ...KIOSK_LOCATION,
    previous: MOBILITY_PARKING.key,
    next: REMOTE_KEY.key,
  },
  KEY: {
    ...KEY,
    previous: MOBILITY_PARKING.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...KEY.data, isFinalizeStep: true },
  },
  REMOTE_KEY: {
    ...REMOTE_KEY,
    previous: KIOSK_LOCATION.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...REMOTE_KEY.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: KEY.key },
};

export default {
  [SelfServiceOrigin.HOME]: HOME_CHECK_OUT_STEPS,
  [SelfServiceOrigin.ON_SITE]: ON_SITE_CHECK_OUT_STEPS,
  [SelfServiceOrigin.KIOSK]: KIOSK_CHECK_OUT_STEPS,
};
