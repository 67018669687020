import React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';

import NoSignal from 'assets/icons/noSignal.svg';

import { Spinner } from '../ui';

const WEBCAM_PREFIX = 'HD_USB_CAM_';

const getDeviceId = async (rack: number) => {
  const allDevices = await navigator.mediaDevices?.enumerateDevices() ?? [];
  const deviceName = WEBCAM_PREFIX + rack;
  const device = allDevices.find((mediaDeviceInfo) => mediaDeviceInfo.label.startsWith(deviceName));
  return device ? device.deviceId : undefined;
};

const SlotCamera: React.FC<{ rack: number; className?: Argument; hideHint?: boolean }> = ({ rack, className, hideHint }) => {
  const video = React.useRef<HTMLVideoElement>();

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [displayNoSignal, setDisplayNoSignal] = React.useState(false);

  const streamCamVideo = React.useCallback(async () => {
    const deviceId = await getDeviceId(rack);
    setDisplayNoSignal(!deviceId);
    if (deviceId && video.current) {
      const constraints = { audio: false, video: { deviceId } } as MediaStreamConstraints;
      video.current.srcObject = await navigator.mediaDevices.getUserMedia(constraints);
    }
  }, [rack]);

  React.useLayoutEffect(() => {
    const playHandler = () => setIsPlaying(true);
    const pauseHandler = () => setIsPlaying(false);

    const videoRef = video.current;
    if (videoRef) {
      videoRef.addEventListener('play', playHandler);
      videoRef.addEventListener('pause', pauseHandler);

      streamCamVideo();

      return () => {
        const stream = videoRef.srcObject as MediaStream;
        stream?.getTracks().forEach((track) => track.stop());

        videoRef.removeEventListener('play', playHandler);
        videoRef.removeEventListener('pause', pauseHandler);
      };
    }

    return undefined;
  }, [streamCamVideo, rack]);

  const isLoading = !isPlaying && !displayNoSignal;

  return (
    <div className={classNames('bg-gray-90 p-4 rounded-3xl w-118', className)}>
      <div className="relative">
        {isPlaying && (
          <div className="absolute left-1/2 -translate-x-1/2 bg-white/40 rounded-3xl bg-white mt-3.5 px-4 py-1.5">
            <div className="size-3 mr-2 bg-error rounded-full inline-block z-10 animate-pulse" />
            <span className="font-bold !text-base">Live</span>
          </div>
        )}
        {isLoading && <Spinner />}
        {displayNoSignal && (
          <div className="rounded-lg aspect-4/3 bg-white flex flex-col justify-center items-center gap-6">
            <div className="rounded-full size-36 p-8 bg-secondary">
              <NoSignal />
            </div>
            <span className="text-gray-70 font-bold">
              <FormattedMessage id="video.noSignal" defaultMessage="No signal" />
            </span>
          </div>
        )}
        {!displayNoSignal && <video className="rounded-lg aspect-4/3" autoPlay ref={video} />}
      </div>
      {!hideHint && (
        <div className="p-8">
          <div className="mx-auto bg-transparent size-0 border-x-[94px] border-x-transparent border-b-[37px] border-b-white" />
          <span className="mt-4 block font-bold !text-4xl text-white text-center uppercase break-anywhere">
            put the keys
            <br />
            in the next slot
          </span>
        </div>
      )}
    </div>
  );
};

export default SlotCamera;
