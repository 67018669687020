import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'components/ui';
import { ButtonType } from 'components/ui/Button';

import Arrow from 'assets/icons/arrow.svg';

const SelfServiceTypeButtons = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const handleClick = (atDealership: boolean) => () => {
    navigate(`/login${search}`, {
      state: {
        atDealership,
      },
    });
  };

  return (
    <div
      className="flex flex-col items-start w-full m-auto pt-6 pb-12 max-w-4xl px-7"
    >
      <Button
        analytics={{
          category: 'Login',
          action: 'From home login',
          label: 'Login started',
        }}
        type={ButtonType.PRIMARY}
        testId="from-home-button"
        onClick={handleClick(false)}
        className="flex items-center justify-between w-full mb-6 !drop-shadow-md !p-1.5 !pl-12"
      >
        <FormattedMessage id="home.fromHome" defaultMessage="From home" />
        <div className="flex justify-center items-center size-20 rounded-full bg-raised">
          <Arrow className="w-4 stroke-gray-90 stroke-1" />
        </div>
      </Button>
      <Button
        analytics={{
          category: 'Login',
          action: 'From the dealership login',
          label: 'Login started',
        }}
        type={ButtonType.PRIMARY}
        testId="from-dealer-button"
        onClick={handleClick(true)}
        className="flex items-center justify-between w-full !drop-shadow-md !p-1.5 !pl-12"
      >
        <FormattedMessage id="home.fromDealer" defaultMessage="From the dealership" />
        <div className="flex justify-center items-center size-20 rounded-full bg-raised">
          <Arrow className="w-4 stroke-gray-90 stroke-1" />
        </div>
      </Button>
    </div>
  );
};

export default SelfServiceTypeButtons;
