/* eslint-disable import/prefer-default-export */
import GenericQuestion, { QuestionType } from 'types/Question';

import { KioskPreferencesQuestionsIds } from './constants';
import { SelfServiceStepConfig } from './types/SelfServiceStepConfig';
import { SelfService, SelfServiceOrigin, SelfServiceType } from './types/SelfService';

export const generateKioskPreferencesQuestions = (selfService: SelfService) => {
  const questions: GenericQuestion[] = [
    {
      id: KioskPreferencesQuestionsIds.CHECK_OUT,
      mandatory: true,
      title: {
        id: 'page.kioskUsage.question.checkOut',
        defaultMessage: 'I wish to use the kiosk to retrieve my keys after my appointment.',
      },
      type: QuestionType.YES_NO,
    },
  ];

  if (selfService.origin === SelfServiceOrigin.HOME) {
    questions.unshift({
      id: KioskPreferencesQuestionsIds.CHECK_IN,
      mandatory: true,
      title: {
        id: 'page.kioskUsage.question.checkIn',
        defaultMessage: 'I wish to use the kiosk to drop my keys off before my appointment.',
      },
      type: QuestionType.YES_NO,
    });
  }

  return questions;
};

export const isKioskPreferenceQuestionVisible = (
  id: KioskPreferencesQuestionsIds,
  type: SelfServiceType,
  origin: SelfServiceOrigin,
  config: SelfServiceStepConfig,
): boolean => {
  const isCheckInQuestion = id === KioskPreferencesQuestionsIds.CHECK_IN;
  const isCheckOutQuestion = id === KioskPreferencesQuestionsIds.CHECK_OUT;

  const hasKioskCheckIn = config.selfServiceAvailableTypes?.CHECK_IN?.includes(SelfServiceOrigin.KIOSK);
  const hasKioskCheckOut = config.selfServiceAvailableTypes?.CHECK_OUT?.includes(SelfServiceOrigin.KIOSK);

  if (isCheckInQuestion) {
    return (
      type === SelfServiceType.CHECK_IN
      && origin === SelfServiceOrigin.HOME
      && hasKioskCheckIn
      && config.hasAcceptedMobility
    );
  }

  return isCheckOutQuestion ? hasKioskCheckOut : false;
};
