import { SOCKET_UPDATE_STEPS } from 'modules/sockets/constants';

import SelfServiceStatus from 'modules/selfServices/types/SelfServiceStatus';
import { KioskPreferencesQuestionsIds } from 'modules/selfServices/constants';
import { isKioskPreferenceQuestionVisible } from 'modules/selfServices/utils';
import { DocumentStatus, SelfServiceOrigin, SignatureStatus } from 'modules/selfServices/types/SelfService';
import { SelfServiceAPISteps, SelfServiceSteps } from 'modules/steps/types/SelfServiceSteps';

import Extras from 'components/Extras';
import RepairOrder from 'components/RepairOrder';
import CustomerInfo from 'components/CustomerInfo';
import MobilityDocument from 'components/Mobility';
import KioskUsageForm from 'components/KioskUsage';
import VehicleCheckStep from 'components/VehicleCheckStep';
import Instructions from 'components/VehicleCheckStep/Instructions';

import {
  KEY,
  PARKING,
  SIGNATURE,
  isNotAnswered,
  KIOSK_LOCATION,
  SelfServiceStep,
  QUESTIONS as COMMON_QUESTIONS,
  FINAL_INSTRUCTIONS as COMMON_FINAL_INSTRUCTIONS, REMOTE_KEY,
} from './common';
import CourtesyVehicle from '../../CourtesyVehicle';

const CUSTOMER_INFO: SelfServiceStep = {
  key: SelfServiceSteps.CUSTOMER_INFO,
  apiKey: SelfServiceAPISteps.CONTACT_INFO,
  component: CustomerInfo,
  next: SelfServiceSteps.REPAIR_ORDER,
  data: {
    preventBrowserNavigation: true,
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

const REPAIR_ORDER: SelfServiceStep = {
  key: SelfServiceSteps.REPAIR_ORDER,
  apiKey: SelfServiceAPISteps.REPAIR_ORDER,
  component: RepairOrder,
  previous: CUSTOMER_INFO.key,
  next: SelfServiceSteps.EXTRAS,
  data: {
    socketUpdateFields: SOCKET_UPDATE_STEPS.REPAIR_ORDER,
    isVisible: ({ status }) => isNotAnswered(status),
  },
};

const EXTRAS: SelfServiceStep = {
  key: SelfServiceSteps.EXTRAS,
  apiKey: SelfServiceAPISteps.EXTRAS_ADDITIONAL_SERVICES,
  component: Extras,
  previous: REPAIR_ORDER.key,
  next: SelfServiceSteps.QUESTIONS,
  data: {
    socketUpdateFields: SOCKET_UPDATE_STEPS.EXTRAS,
    isVisible: ({ status, checklist }) => isNotAnswered(status) && Boolean(checklist?.items?.[0]?.advices),
  },
};

const QUESTIONS: SelfServiceStep = {
  ...COMMON_QUESTIONS,
  previous: EXTRAS.key,
  data: {
    ...COMMON_QUESTIONS.data,
    socketUpdateFields: SOCKET_UPDATE_STEPS.QUESTIONS,
  },
};

const KIOSK_USAGE: SelfServiceStep = {
  key: SelfServiceSteps.KIOSK_USAGE,
  apiKey: SelfServiceAPISteps.KIOSK_USAGE_QUESTION,
  component: KioskUsageForm,
  data: {
    isVisible: ({ status, type, origin }, config) => {
      const isCheckInVisible = isKioskPreferenceQuestionVisible(
        KioskPreferencesQuestionsIds.CHECK_IN,
        type,
        origin,
        config,
      );
      const isCheckOutVisible = isKioskPreferenceQuestionVisible(
        KioskPreferencesQuestionsIds.CHECK_OUT,
        type,
        origin,
        config,
      );
      return isNotAnswered(status) && (isCheckInVisible || isCheckOutVisible);
    },
  },
};

const MOBILITY_DOCUMENTS: SelfServiceStep = {
  key: SelfServiceSteps.MOBILITY_DOCUMENTS,
  apiKey: SelfServiceAPISteps.MOBILITY,
  component: MobilityDocument,
  previous: QUESTIONS.key,
  next: KIOSK_USAGE.key,
  data: {
    // This step is visible if the self-servcie has mobility enabled and it is not answered or has documents to validate
    isVisible: ({ status, mobility }) => {
      if (mobility) {
        if (isNotAnswered(status)) {
          return true;
        }

        const { documents } = mobility;
        return documents?.some(({ status: documentStatus }) => documentStatus === DocumentStatus.REQUESTED) ?? false;
      }
      return false;
    },
    // When the self-service is answered and has documents to validate this step updates it
    isFinalizeStep: ({ status, mobility }) => status === SelfServiceStatus.ANSWERED
      && (!mobility?.contract || mobility.contract.status === SignatureStatus.SIGNED),
  },
};

export const VEHICLE_CHECK: SelfServiceStep = {
  key: SelfServiceSteps.VEHICLE_CHECK,
  apiKey: SelfServiceAPISteps.VEHICLE_CHECK_QUESTION,
  component: VehicleCheckStep,
  data: {
    isVisible: (
      { status },
      { vehicleCheckEnabled, courtesyVehicleCheckEnabled },
    ) => isNotAnswered(status) && (vehicleCheckEnabled || courtesyVehicleCheckEnabled),
  },
};

const COURTESY_VEHICLE: SelfServiceStep = {
  key: SelfServiceSteps.COURTESY_VEHICLE,
  apiKey: SelfServiceAPISteps.COURTESY_CAR_INFO,
  component: CourtesyVehicle,
  previous: KIOSK_USAGE.key,
  next: PARKING.key,
  data: {
    isVisible: (
      { status, mobility, mobilityEnabled },
      { hasAcceptedMobility },
    ) => (mobilityEnabled || hasAcceptedMobility) && isNotAnswered(status) && Boolean(mobility?.vehicle),
  },
};

const VEHICLE_CHECK_QR_CODE: SelfServiceStep = {
  key: SelfServiceSteps.VEHICLE_CHECK_QR_CODE,
  apiKey: SelfServiceAPISteps.GUIDED_VEHICLE_CHECK,
  component: Instructions,
  data: {
    hideStepIndicator: true,
    hidePrevButton: true,
    isVisible: ({
      vehicleCheckAccepted,
      vehicleCheck,
      courtesyVehicleCheckAccepted,
      courtesyVehicleCheck,
    }, {
      vehicleCheckEnabled,
      vehicleCheckAccepted: configVehicleCheckAccepted,
      courtesyVehicleCheckEnabled,
      courtesyVehicleCheckAccepted: configCourtesyVehicleCheckAccepted,
    }) => {
      let isVisible = false;

      if (vehicleCheckEnabled) {
        isVisible = (vehicleCheckAccepted || configVehicleCheckAccepted) && !vehicleCheck;
      }

      if (!isVisible && courtesyVehicleCheckEnabled) {
        isVisible = (courtesyVehicleCheckAccepted || configCourtesyVehicleCheckAccepted) && !courtesyVehicleCheck;
      }

      return isVisible;
    },
  },
};

export const FINAL_INSTRUCTIONS: SelfServiceStep = {
  ...COMMON_FINAL_INSTRUCTIONS,
  data: {
    ...COMMON_FINAL_INSTRUCTIONS.data,
    socketUpdateFields: SOCKET_UPDATE_STEPS.FINAL_INSTRUCTIONS,
  },
};

const COMMON_CHECKIN_STEPS: Record<string, SelfServiceStep> = {
  CUSTOMER_INFO,
  REPAIR_ORDER,
  EXTRAS,
};

const COMMON_ON_SITE_KIOSK_CHECKIN_STEPS: Record<string, SelfServiceStep> = {
  QUESTIONS: { ...QUESTIONS, previous: EXTRAS.key, next: KIOSK_USAGE.key },
  KIOSK_USAGE: { ...KIOSK_USAGE, previous: QUESTIONS.key, next: COURTESY_VEHICLE.key },
  COURTESY_VEHICLE,
  PARKING: { ...PARKING, previous: COURTESY_VEHICLE.key, next: VEHICLE_CHECK.key },
  VEHICLE_CHECK: { ...VEHICLE_CHECK, previous: PARKING.key, next: SIGNATURE.key },
};

const HOME_CHECK_IN_STEPS: Record<string, SelfServiceStep> = {
  ...COMMON_CHECKIN_STEPS,
  QUESTIONS: { ...QUESTIONS, next: MOBILITY_DOCUMENTS.key },
  MOBILITY_DOCUMENTS,
  KIOSK_USAGE: { ...KIOSK_USAGE, previous: MOBILITY_DOCUMENTS.key, next: SIGNATURE.key },
  SIGNATURE: {
    ...SIGNATURE,
    apiKey: SelfServiceAPISteps.SUMMARY_SIGNATURE,
    previous: KIOSK_USAGE.key,
    next: FINAL_INSTRUCTIONS.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  FINAL_INSTRUCTIONS: {
    ...FINAL_INSTRUCTIONS,
    apiKey: SelfServiceAPISteps.INSTRUCTIONS,
    previous: SIGNATURE.key,
  },
};

const ON_SITE_CHECK_IN_STEPS: Record<string, SelfServiceStep> = {
  ...COMMON_CHECKIN_STEPS,
  ...COMMON_ON_SITE_KIOSK_CHECKIN_STEPS,
  SIGNATURE: {
    ...SIGNATURE,
    previous: VEHICLE_CHECK.key,
    next: VEHICLE_CHECK_QR_CODE.key,
    data: { ...SIGNATURE.data, isFinalizeStep: true },
  },
  VEHICLE_CHECK_QR_CODE: { ...VEHICLE_CHECK_QR_CODE, previous: SIGNATURE.key, next: FINAL_INSTRUCTIONS.key },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: VEHICLE_CHECK_QR_CODE.key },
};

const KIOSK_CHECK_IN_STEPS: Record<string, SelfServiceStep> = {
  ...COMMON_CHECKIN_STEPS,
  ...COMMON_ON_SITE_KIOSK_CHECKIN_STEPS,
  SIGNATURE: { ...SIGNATURE, previous: VEHICLE_CHECK.key, next: KIOSK_LOCATION.key },
  KIOSK_LOCATION: {
    ...KIOSK_LOCATION,
    previous: SIGNATURE.key,
    next: REMOTE_KEY.key,
  },
  KEY: {
    ...KEY,
    previous: SIGNATURE.key,
    next: VEHICLE_CHECK_QR_CODE.key,
    data: { ...KEY.data, isFinalizeStep: true },
  },
  REMOTE_KEY: {
    ...REMOTE_KEY,
    previous: KIOSK_LOCATION.key,
    next: VEHICLE_CHECK_QR_CODE.key,
    data: { ...REMOTE_KEY.data, isFinalizeStep: true },
  },
  VEHICLE_CHECK_QR_CODE: { ...VEHICLE_CHECK_QR_CODE, previous: KEY.key, next: FINAL_INSTRUCTIONS.key },
  FINAL_INSTRUCTIONS: { ...FINAL_INSTRUCTIONS, previous: VEHICLE_CHECK_QR_CODE.key },
};

export default {
  [SelfServiceOrigin.HOME]: HOME_CHECK_IN_STEPS,
  [SelfServiceOrigin.ON_SITE]: ON_SITE_CHECK_IN_STEPS,
  [SelfServiceOrigin.KIOSK]: KIOSK_CHECK_IN_STEPS,
};
