import React from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { Transition } from '@headlessui/react';
import KeyboardReact, { KeyboardLayoutObject, KeyboardReactInterface } from 'react-simple-keyboard';

import { isShareboxIndoorKiosk } from 'modules/dealers/selectors';

import { Input } from 'components/ui';

// See https://stackoverflow.com/a/46012210
const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
const nativeTextAreaValueSetter = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;

const WRITABLE_INPUT_TYPES = ['textarea', 'email', 'text', 'number', 'search', 'url', 'tel', 'password'];

const DEFAULT_EXCLUDED_KEYS = {
  default: ['{tab}', '{lock}', '`', '=', '[', ']', '\\', ';', ',', '/', '.com', '@'],
  shift: ['{tab}', '{lock}', '!', '#', '$', '%', '^', '*', '(', ')', '{', '}', '|', ':', '<', '>', '.com'],
};

const NUMERICAL_LAYOUT = {
  default: ['1 2 3', '4 5 6', '7 8 9', '+ 0 {bksp}', '{enter}'],
};

const BUTTON_LABELS = {
  '{enter}': 'OK',
  '{shift}': '⬆',
  '{space}': '␣',
  '{bksp}': '⌫',
};

const TYPE_LAYOUT: Record<string, KeyboardLayoutObject> = {
  number: NUMERICAL_LAYOUT,
  tel: NUMERICAL_LAYOUT,
};

const getButtonThemes = (shift: boolean) => [
  {
    class: '!bg-success !text-white font-bold',
    buttons: '{enter}',
  },
  {
    class: '!bg-error !text-white font-bold',
    buttons: '{bksp}',
  },
  {
    class: shift ? '!bg-gray-200' : 'bg-white', // `bg-white` is just a placeholder, it is not really used
    buttons: '{shift}',
  },
];

const getPortalContainer = () => {
  let portal = document.getElementById('keyboard-portal');

  if (!portal) {
    portal = document.createElement('div');
    portal.id = 'keyboard-portal';
    portal.className = 'fixed z-60';
    document.body.appendChild(portal);
  }

  return portal;
};

const KeyboardModal = () => {
  const focusedInput = React.useRef<HTMLInputElement | null>();

  const [value, setValue] = React.useState('');
  const [show, setShow] = React.useState(false);
  const [layoutName, setLayoutName] = React.useState('default');
  const [layout, setLayout] = React.useState<KeyboardLayoutObject>();

  const onFocus = React.useCallback(({ target }: Event) => {
    focusedInput.current = target as HTMLInputElement;

    setLayoutName('default');
    setLayout(TYPE_LAYOUT[focusedInput.current.type]);

    setValue(focusedInput.current.value);
    setShow(true);
  }, []);

  const onClose = React.useCallback((text?: string) => {
    setShow(false);

    if (typeof text === 'string') {
      // Sets the value of the focused element using native setter
      if (focusedInput.current instanceof HTMLTextAreaElement) {
        nativeTextAreaValueSetter.call(focusedInput.current, text);
      } else {
        nativeInputValueSetter.call(focusedInput.current, text);
      }

      // Dispatches a native `input` event after setting the value to trigger React's onChange
      const event = new Event('input', { bubbles: true });
      focusedInput.current.dispatchEvent(event);
    }

    // This ensures our portal will always be the last child of the body as it will be recreated
    document.body.removeChild(getPortalContainer());
  }, []);

  // We use onKeyReleased instead of onKeyPress to hide the keyboard
  // because the event keeps getting called even after the keyboard is closed with the latter
  const handleKeyReleased = React.useCallback((which: string) => {
    if (which === '{shift}') {
      setLayoutName(layoutName === 'default' ? 'shift' : 'default');
    } else if (which === '{enter}') {
      onClose(value);
    } else if (which === '{tab}') {
      onClose();
    }
  }, [layoutName, value, onClose]);

  const handleKeyboardInit = React.useCallback((keyboard: KeyboardReactInterface) => {
    keyboard.setInput(value);
  }, [value]);

  const handleClick = React.useCallback(() => onClose(), [onClose]);

  React.useLayoutEffect(() => {
    let inputs: NodeListOf<HTMLInputElement>;

    const observer = new MutationObserver(() => {
      inputs = document.querySelectorAll('input,textarea');
      inputs.forEach((input) => {
        if (WRITABLE_INPUT_TYPES.includes(input.type) && input.id !== 'keyboard-input') {
          // Ensure that the event listener is only added once
          input.removeEventListener('focus', onFocus);
          input.addEventListener('focus', onFocus);
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => {
      inputs?.forEach((input) => input.removeEventListener('focus', onFocus));
      observer.disconnect();
    };
  }, [onFocus]);

  // We need to use a portal here to ensure that the keyboard is always on top of everything (ie. including modals)
  return createPortal(
    (
      <Transition appear show={show} as={React.Fragment}>
        <div className="z-60 fixed top-0 bottom-0 left-0 right-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-linear duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-linear duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed h-screen w-screen backdrop-blur-sm backdrop-grayscale" onClick={handleClick} />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-linear duration-100"
            enterFrom="translate-y-full"
            enterTo="translate-y-0"
            leave="ease-linear duration-100"
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <div className="fixed w-screen bottom-0">
              <Input id="keyboard-input" className="!rounded-none bg-input-bg p-4" inputClassName="!rounded-lg bg-white !p-4" value={value} />
              <KeyboardReact
                mergeDisplay
                layout={layout}
                onChange={setValue}
                display={BUTTON_LABELS}
                layoutName={layoutName}
                onInit={handleKeyboardInit}
                onKeyReleased={handleKeyReleased}
                excludeFromLayout={DEFAULT_EXCLUDED_KEYS}
                buttonTheme={getButtonThemes(layoutName === 'shift')}
              />
            </div>
          </Transition.Child>
        </div>
      </Transition>
    ), getPortalContainer(),
  );
};

// This is a safeguard to ensure that the keyboard is only displayed on Sharebox kiosks
const KeyboardModalWrapper = () => {
  const allowKeyboard = useSelector(isShareboxIndoorKiosk);
  return allowKeyboard && <KeyboardModal />;
};

export default KeyboardModalWrapper;
