import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export enum ErrorTypeEnum {
  MFA_CODE_REACHED = 'MFA_CODE_REACHED',
  MFA_CODE_TIMEOUT = 'MFA_CODE_TIMEOUT',
  MFA_MAX_ATTEMPT_REACHED = 'MFA_MAX_ATTEMPT_REACHED',
  MFA_WRONG_CODE = 'MFA_WRONG_CODE',
  MFA_WRONG_CONNECTION_STATUS = 'MFA_WRONG_CONNECTION_STATUS',
  WRONG_STATUS = 'SELF_SERVICE_WRONG_STATUS',
  FINAL_INVOICE_ENABLED = 'FINAL_INVOICE_ENABLED',
  SO_CHECK_OUT_ALREADY_COMPLETED = 'SO_CHECK_OUT_ALREADY_COMPLETED ',
  SO_CHECK_IN_KIOSK_ALL_SLOTS_USED = 'SO_CHECK_IN_KIOSK_ALL_SLOTS_USED',
  SO_KIOSK_WRONG_PIN_CODE = 'SO_KIOSK_WRONG_PIN_CODE',
  SO_COURTESY_VEHICLE_NOT_AVAILABLE = 'SO_COURTESY_VEHICLE_NOT_AVAILABLE',
  SO_PHONE_NUMBER_NOT_VALID = 'SO_PHONE_NUMBER_NOT_VALID',
  SO_WRONG_QR_CODE = 'SO_WRONG_QR_CODE',
  SO_WRONG_REGISTRATION = 'SO_WRONG_REGISTRATION',
  SO_WRONG_SHAREBOX_LOCATION_ID = 'SO_WRONG_SHAREBOX_LOCATION_ID',
}

export interface APIError {
  errorCode: number;
  errorMessage: string;
  errorStatus: string;
  errorType?: ErrorTypeEnum;
}

export interface ApiErrorResponse {
  status: number;
  data: APIError;
}

export const isApiErrorResponse = (error: SerializedError | FetchBaseQueryError | ApiErrorResponse): error is ApiErrorResponse => (
  typeof (error as ApiErrorResponse)?.status === 'number'
);
