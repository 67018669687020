import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';

import { KeyStatus } from 'modules/kiosk/types/Kiosk';
import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { createDictionary } from 'utils/objectUtils';
import { openKeysSafe } from 'modules/kiosk/actions';
import { getIsCloseSuccess } from 'modules/kiosk/selectors';
import { getKioskLocation } from 'modules/dealers/selectors';
import { KioskLocation } from 'modules/dealers/types/ContextResponse';
import { getSelectedSelfService } from 'modules/selfServices/selectors';

import PageBaseProps from '../../types/PageBase';
import shareboxOutdoorKiosk from '../../assets/images/shareboxOutdoorKiosk.png';
import shareboxIndoorKiosk from '../../assets/images/shareboxIndoorKiosk.png';

const defaultMessage = createDictionary<string>({
  [`mobility_${KioskLocation.INDOOR}`]: 'Swap the vehicle keys and close the door.',
  [`mobility_${KioskLocation.OUTDOOR}`]: 'You can open the kiosk by clicking the green blinking button. Swap the vehicle keys and click again to close the door.',
  [`${SelfServiceType.CHECK_IN}_${KioskLocation.INDOOR}`]: 'Place the keys inside and close the door.',
  [`${SelfServiceType.CHECK_IN}_${KioskLocation.OUTDOOR}`]: 'You can open the kiosk by clicking the green blinking button. Place the keys inside and click again to close the door.',
  [`${SelfServiceType.CHECK_OUT}_${KioskLocation.INDOOR}`]: 'Retrieve the keys and close the door.',
  [`${SelfServiceType.CHECK_OUT}_${KioskLocation.OUTDOOR}`]: 'You can open the kiosk by clicking the green blinking button. Retrieve the keys inside and click again to close the door.',
}, '');

const RemoteKey: React.FC<PageBaseProps> = ({ onNext }) => {
  const dispatch = useDispatch();
  const kioskLocation = useSelector(getKioskLocation);
  const isDoorClosed = useSelector(getIsCloseSuccess);
  const selfService = useSelector(getSelectedSelfService);

  React.useEffect(() => {
    dispatch(openKeysSafe({ selfServiceId: selfService.id, selfServiceType: selfService.type }));
  }, [dispatch, selfService.id, selfService.type]);

  React.useEffect(() => {
    if (isDoorClosed) {
      onNext();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDoorClosed]);

  const type = selfService.type === SelfServiceType.BREAKDOWN ? SelfServiceType.CHECK_IN : selfService.type;
  const mobilityEnabled = selfService.mobilityEnabled && (selfService.type !== SelfServiceType.CHECK_IN || selfService.keyStatus === KeyStatus.IN_SLOT);

  const description = React.useMemo(() => {
    if (mobilityEnabled) {
      return {
        id: `remoteKey.mobility.${kioskLocation}.indication`,
        defaultMessage: defaultMessage[`mobility_${kioskLocation}`],
      };
    }
    return {
      id: `remoteKey.${type}.${kioskLocation}.indication`,
      defaultMessage: defaultMessage[`${type}_${kioskLocation}`],
    };
  }, [type, mobilityEnabled, kioskLocation]);

  return (
    <div className="main-content max-h-svh overflow-hidden">
      <div className="h-full flex grow flex-col items-center">
        <h1>
          {mobilityEnabled && (
            <FormattedMessage
              id="key.mobility.title"
              defaultMessage="Swap keys"
            />
          )}
          {!mobilityEnabled && (
            <FormattedMessage
              id={`key.${type}.title`}
              defaultMessage={type === SelfServiceType.CHECK_IN ? 'Drop keys' : 'Retrieve keys'}
            />
          )}
        </h1>
        <div className="content pb-10">
          <p className="text-center mt-5 text-gray-70"><FormattedMessage {...description} /></p>
        </div>
        <div className="grow flex items-end justify-center w-full ">
          <img
            alt="Sharebox kiosk"
            src={kioskLocation === KioskLocation.OUTDOOR ? shareboxOutdoorKiosk : shareboxIndoorKiosk}
            className=" translate-y-4 landscape:max-w-64 lg:landscape:max-w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default RemoteKey;
