import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'hooks';
import useQrCodeScanner from 'hooks/useQrCodeScanner';

import { useGetContextQuery } from 'modules/dealers/service';

import {
  displayOriginSelection,
  isKioskEmergencyMode,
  shouldDisplayPhoneNumbers as shouldDisplayPhoneNumbersSelector,
} from 'modules/dealers/selectors';

import DealersPhone from 'components/ui/DealersPhone';

import Warning from 'assets/icons/warning.svg';

import Header from './Header';
import Background from './Background';
import SelfServiceTypeButtons from './SelfServiceTypeButtons';
import SelfServiceOriginButtons from './SelfServiceOriginButtons';

import './index.css';

const Home: React.FC = () => {
  useQrCodeScanner();

  const hasKioskDoorsError = useSelector(isKioskEmergencyMode);
  const displayOriginButtons = useSelector(displayOriginSelection);
  const shouldDisplayPhoneNumbers = useSelector(shouldDisplayPhoneNumbersSelector);

  const { data: contextData, isFetching } = useGetContextQuery();
  const {
    dealerName, brand, type, kioskName, kioskBackgroundMedia, backgroundMedia,
  } = contextData ?? {};

  const homeTitle = (!displayOriginButtons && kioskName) || dealerName;

  return (
    <Background
      loading={isFetching}
      media={kioskBackgroundMedia ?? backgroundMedia}
    >
      <Header />
      <div className="flex flex-col justify-between grow w-full max-w-7xl z-30">
        <div className="w-full max-w-7xl m-auto text-white mt-8 grow sm:grow-0 px-7">
          <div className="flex items-center flex-col">
            {brand && (
              <div className="size-24 md:w-32 md:h-32" data-testid="logo">
                <div className="bg-white p-3 size-full flex mask-image">
                  <img src={brand.logoPathMobile} alt={brand.name} className="m-auto logo-home" />
                </div>
              </div>
            )}
            {homeTitle && (
              <h1
                className={classNames('home drop-shadow-md opacity-70 uppercase font-bold', {
                  'truncate w-full': kioskName,
                })}
              >
                {homeTitle}
              </h1>
            )}
            <h2 className="home drop-shadow-md">
              <FormattedMessage
                id="home.title"
                // eslint-disable-next-line max-len
                defaultMessage="{type, select, CHECK_IN {Ready for your check-in} CHECK_OUT {Ready for your check-out} other {How can we help you} }?"
                values={{ type }}
              />
            </h2>
          </div>
        </div>
        {hasKioskDoorsError && (
          <div className="flex flex-col items-center m-auto max-w-4xl w-full p-20 mb-52 bg-input-bg rounded-4xl">
            <div className="rounded-full size-52 bg-white relative flex justify-center items-center mr-5">
              <Warning className="absolute size-28" />
            </div>
            <span className="flex flex-col items-center gap-5 text-center mt-10">
              <FormattedMessage id="home.emergency.title" defaultMessage="Issues with the kiosk" tagName="h1" />
              <FormattedMessage
                id="home.emergency.description"
                defaultMessage="We are currently experiencing an issue with the kiosk, please visit the dealership or contact us via the number below."
                tagName="span"
              />
            </span>
            {shouldDisplayPhoneNumbers && <DealersPhone />}
          </div>
        )}

        {!hasKioskDoorsError && (
          <>
            {displayOriginButtons && <SelfServiceOriginButtons />}
            {!displayOriginButtons && <SelfServiceTypeButtons />}
          </>
        )}
      </div>
    </Background>
  );
};

export default Home;
