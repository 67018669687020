import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  getOrigin,
  getSelfServiceType,
  getSelfServiceAvailableTypes,
  isKioskFull as isKioskFullSelector,
} from 'modules/dealers/selectors';
import { isLocalKioskOffline as isKioskOfflineSelector } from 'modules/healthCheck/selectors';

import { Button } from 'components/ui';
import Popup from 'components/ui/Popup';
import { ButtonType } from 'components/ui/Button';

import { SelfServiceType } from 'modules/selfServices/types/SelfService';

import { useSelector } from '../../hooks';

import CheckInLogo from '../../assets/icons/checkIn.svg';
import CheckOutLogo from '../../assets/icons/checkOut.svg';
import BreakdownLogo from '../../assets/icons/breakdown.svg';

const SelfServiceTypeButtons = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const origin = useSelector(getOrigin);
  const type = useSelector(getSelfServiceType);
  const isKioskFull = useSelector(isKioskFullSelector);
  const isKioskOffline = useSelector(isKioskOfflineSelector);
  const selfServiceAvailableTypes = useSelector(getSelfServiceAvailableTypes);

  const [showFullPopup, setShowFullPopup] = React.useState(false);

  const shouldShowCheckIn = type === SelfServiceType.CHECK_IN || (!type && selfServiceAvailableTypes[SelfServiceType.CHECK_IN]?.includes(origin));
  const shouldShowCheckOut = type === SelfServiceType.CHECK_OUT || (!type && selfServiceAvailableTypes[SelfServiceType.CHECK_OUT]?.includes(origin));
  const shouldShowBreakdown = selfServiceAvailableTypes[SelfServiceType.BREAKDOWN]?.includes(origin);

  const handleCloseFullPopup = React.useCallback(() => setShowFullPopup(false), []);

  const handleClick = (selectedType: SelfServiceType) => () => {
    if (selectedType === SelfServiceType.BREAKDOWN && isKioskFull) {
      setShowFullPopup(true);
    } else {
      navigate(`/login${search}`, {
        state: {
          type: selectedType,
        },
      });
    }
  };

  return (
    <div
      className="flex flex-col items-start w-full m-auto pt-6 pb-12 max-w-4xl kiosk:w-full px-7 kiosk:px-12 kiosk:mb-52 kioskSharebox:mb-12"
    >
      {shouldShowCheckIn && (
        <Button
          analytics={{
            category: 'Login',
            action: 'CHECK_IN login',
            label: 'Login started',
          }}
          disabled={isKioskOffline}
          testId="check-in-button"
          type={ButtonType.PRIMARY}
          onClick={handleClick(SelfServiceType.CHECK_IN)}
          className={classNames('flex items-center justify-center w-full !drop-shadow-md', { 'mb-6': shouldShowCheckOut })}
        >
          <CheckInLogo className="w-12 mr-8 fill-gray-90" />
          <FormattedMessage id="home.checkIn" defaultMessage="Check-in" />
        </Button>
      )}
      {shouldShowCheckOut && (
        <Button
          analytics={{
            category: 'Login',
            action: 'CHECK_OUT login',
            label: 'Login started',
          }}
          disabled={isKioskOffline}
          testId="check-out-button"
          type={ButtonType.PRIMARY}
          className="flex items-center justify-center w-full !drop-shadow-md"
          onClick={handleClick(SelfServiceType.CHECK_OUT)}
        >
          <CheckOutLogo className="w-12 mr-8 fill-gray-90" />
          <FormattedMessage id="home.checkOut" defaultMessage="Check-out" />
        </Button>
      )}
      {shouldShowBreakdown && (
        <>
          <div className="h-px bg-white my-12 opacity-30 mx-auto w-[calc(100%-6rem)] !drop-shadow-md" />
          <Button
            analytics={{
              category: 'Login',
              action: 'BREAKDOWN login',
              label: 'Login started',
            }}
            testId="breakdown-button"
            type={ButtonType.PRIMARY}
            disabled={isKioskOffline}
            disabledStyle={isKioskFull}
            className="flex items-center justify-center w-full !leading-12 !drop-shadow-md"
            onClick={handleClick(SelfServiceType.BREAKDOWN)}
          >
            <BreakdownLogo className="w-12 mr-8 fill-gray-90" />
            <FormattedMessage id="home.breakdown" defaultMessage="Breakdown service" />
          </Button>
        </>
      )}
      {showFullPopup && (
        <Popup onClose={handleCloseFullPopup}>
          <h2 className="py-16 text-center">
            <FormattedMessage
              id="kiosk.fullWarning"
              defaultMessage="The kiosk is full. It is no longer possible to drop keys."
              tagName="h2"
            />
          </h2>
        </Popup>
      )}
    </div>
  );
};

export default SelfServiceTypeButtons;
