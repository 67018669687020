import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

import api from 'service';
import { br } from 'utils/i18nUtils';

import {
  ADMIN, KIOSK, ROOT, SELF_SERVICE,
} from 'constants/url';

import { addNotification } from 'modules/notifications/actions';

import { NotificationType } from 'modules/notifications/types/Notification';

import { getAdminToken, getToken } from 'modules/auth/selectors';

import { NOTIFICATION_TIMEOUT } from 'components/Notifications';

import { Kiosk } from './types/Kiosk';
import { RootState } from '../../App/Store';
import { ApiErrorResponse } from '../../types/Error';
import { GetKioskDataRequest } from './types/GetKioskData';
import { CloseKeysSafeRequest, OpenKeysSafeRequest, OpenKeysSafeResponse } from './types/KeysSafe';
import { GetReadySelfServicesRequest, GetReadySelfServicesResponse } from './types/GetReadySelfServices';

const kioskApi = api.injectEndpoints({
  endpoints: (build) => ({
    getKioskData: build.query<Kiosk, GetKioskDataRequest>({
      query: ({ kioskId }) => ({
        method: 'GET',
        url: `admin${KIOSK}/${kioskId}`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'admin.errors.kioskError',
                defaultMessage: 'An error occurred while getting kiosk data',
              },
              description: {
                id: 'admin.errors.retry',
                defaultMessage: 'Please try again. If the problem persists, contact the support team.',
              },
              collapse: {
                title: {
                  id: 'admin.errors.supportContact',
                  defaultMessage: 'Support contact information',
                },
                description: {
                  id: 'admin.errors.support',
                  defaultMessage: 'Mo-Fr from 9am to 5pm. (CET){br}support@fleetback.com{br}+32 2 80 80 828',
                  values: { br },
                },
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    openSlot: build.query<OpenKeysSafeResponse, OpenKeysSafeRequest>({
      query: ({ kioskId, selfServiceId }) => ({
        method: 'POST',
        url: `${KIOSK}/${kioskId}${SELF_SERVICE}/${selfServiceId}/slot/open`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.opening',
                defaultMessage: 'An error occurred while opening the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
              duration: NOTIFICATION_TIMEOUT * 3,
            }),
          );
        }
      },
      transformResponse: (response, __, { selfServiceId }) => ({
        ...(response as OpenKeysSafeResponse),
        selfServiceId,
      }),
    }),
    openSlotAsAdmin: build.query<OpenKeysSafeRequest, OpenKeysSafeRequest>({
      query: ({
        kioskId, slotId, selfServiceId, emergency,
      }) => ({
        method: 'POST',
        url: `${emergency ? ROOT : ADMIN}${KIOSK}/${kioskId}/slots/${slotId}/open`,
        body: { selfServiceId },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.opening',
                defaultMessage: 'An error occurred while opening the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
      transformResponse(_, __, request) {
        return request;
      },
    }),
    reset: build.mutation<OpenKeysSafeResponse, OpenKeysSafeRequest>({
      queryFn({
        kioskId, selfServiceId, token, doorTimeout,
      }, { getState }, __, baseQuery) {
        const authToken = token ?? getToken(getState() as RootState);
        return baseQuery({
          method: 'POST',
          url: `${KIOSK}/${kioskId}${SELF_SERVICE}/${selfServiceId}/reset`,
          params: { doorTimeout },
          headers: { Authorization: authToken },
        }) as QueryReturnValue<OpenKeysSafeResponse, ApiErrorResponse, FetchBaseQueryMeta>;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.closing',
                defaultMessage: 'An error occurred while closing the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    closeSlotAsAdmin: build.query<CloseKeysSafeRequest, CloseKeysSafeRequest>({
      query: ({
        kioskId, slotId, selfServiceId, emergency,
      }) => ({
        method: 'POST',
        url: `${emergency ? ROOT : ADMIN}${KIOSK}/${kioskId}/slots/${slotId}/close`,
        body: { selfServiceId },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.closing',
                defaultMessage: 'An error occurred while closing the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
      transformResponse(_, __, request) {
        return request;
      },
    }),
    closeAllSlotsAsAdmin: build.mutation<undefined, { kioskId: string; adminToken?: string }>({
      queryFn({ kioskId, adminToken }, { getState }, __, baseQuery) {
        const authToken = adminToken ?? getAdminToken(getState() as RootState);
        return baseQuery({
          method: 'POST',
          url: `${ADMIN}${KIOSK}/${kioskId}/reset`,
          headers: { Authorization: authToken },
        }) as QueryReturnValue<undefined, ApiErrorResponse, FetchBaseQueryMeta>;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.closing',
                defaultMessage: 'An error occurred while closing the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    updateSlot: build.mutation<undefined, CloseKeysSafeRequest>({
      query: ({ selfServiceId, kioskId, ...body }) => ({
        method: 'PATCH',
        url: `${KIOSK}/${kioskId}${SELF_SERVICE}/${selfServiceId}/keys`,
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.closing',
                defaultMessage: 'An error occurred while closing the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    updateSlotAsAdmin: build.mutation<undefined, CloseKeysSafeRequest>({
      query: ({ slotId, kioskId, ...body }) => ({
        method: 'PATCH',
        url: `${ADMIN}${KIOSK}/${kioskId}/slots/${slotId}/keys`,
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.closing',
                defaultMessage: 'An error occurred while closing the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    unprepareSlotAsAdmin: build.mutation<undefined, CloseKeysSafeRequest>({
      query: ({ selfServiceId, kioskId, slotId }) => ({
        method: 'POST',
        url: `${ADMIN}${KIOSK}/${kioskId}/slots/${slotId}/unprepare`,
        body: { selfServiceId },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.unprepare',
                defaultMessage: 'An error occurred while retrieving the keys',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
    getReadySelfServices: build.query<GetReadySelfServicesResponse, GetReadySelfServicesRequest>({
      query: ({ kioskId, ...params }) => ({
        method: 'GET',
        url: `${ADMIN}${KIOSK}/${kioskId}${SELF_SERVICE}/ready`,
        params,
      }),
      transformResponse: (response: GetReadySelfServicesResponse, _, args) => response.map((selfService) => ({
        ...selfService,
        type: args.type,
      })),
    }),
    openDoorsEmergency: build.query<undefined, { kioskId: string }>({
      query: ({ kioskId }) => ({
        method: 'POST',
        url: `${ADMIN}${KIOSK}/${kioskId}/emergencyOpen`,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            addNotification({
              title: {
                id: 'key.error.opening',
                defaultMessage: 'An error occurred while opening the slot',
              },
              description: {
                id: 'defaultError.description',
                defaultMessage: 'Please try again later or contact us.',
              },
              type: NotificationType.ERROR,
            }),
          );
        }
      },
    }),
  }),
});

export const { useGetKioskDataQuery, useGetReadySelfServicesQuery } = kioskApi;

export default kioskApi;
