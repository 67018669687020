import React from 'react';
import debounce from 'debounce';
import { addSeconds as addSecondsBase } from 'date-fns';

import useCountdown from './useCountdown';

const LISTENERS = ['mousemove', 'keydown', 'touchstart', 'scroll', 'click'];

interface UseInactivityProps {
  seconds: number;
  onInactive?: () => void;
  // If paused is true it will stop the inactivity subscription.
  paused?: boolean;
}

type UseInactivity = (props: UseInactivityProps) => {
  isExpired: boolean;
  restart: () => void;
};

const addSeconds = (seconds: number) => addSecondsBase(Date.now(), seconds);

const useInactivity: UseInactivity = ({ seconds, onInactive, paused }) => {
  // This is used to keep the same reference for the callback when adding/removing listeners.
  const callbackRef = React.useRef<() => void>();

  const [date, setDate] = React.useState(addSeconds(seconds));

  const { countdown } = useCountdown({ date });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResetTimer = React.useCallback(debounce(
    () => {
      setDate(addSeconds(seconds));
    },
    200,
  ), [seconds]);

  const removeListeners = React.useCallback(() => {
    if (callbackRef.current) {
      LISTENERS.forEach((listener) => {
        document.removeEventListener(listener, callbackRef.current);
      });
    }
  }, []);

  const addListeners = React.useCallback(() => {
    removeListeners();

    // Need to keep the same reference for the callback when adding/removing listeners.
    // Also, we wrap it with an arrow function to avoid the debounce to be called with multiple contexts.
    callbackRef.current = () => handleResetTimer();

    LISTENERS.forEach((listener) => {
      document.addEventListener(listener, callbackRef.current);
    });
  }, [removeListeners, handleResetTimer]);

  React.useEffect(() => {
    addListeners();
    return removeListeners;
  }, [removeListeners, addListeners]);

  React.useEffect(() => {
    if (countdown === 0) {
      if (!paused) {
        onInactive?.();
      } else {
        handleResetTimer.clear();
      }
      removeListeners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown]);

  const restart = React.useCallback(() => {
    handleResetTimer();
    addListeners();
  }, [handleResetTimer, addListeners]);

  return React.useMemo(() => ({
    restart,
    isExpired: countdown === 0,
  }), [countdown, restart]);
};

export default useInactivity;
