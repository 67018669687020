import React from 'react';
import classNames, { Argument } from 'classnames';

import MediaLoader from './MediaLoader';

interface ImageProps {
  src: string;
  thumbnail?: boolean;
  className?: Argument;
  watermarkTitle?: string;
}

const Image: React.FC<ImageProps> = ({
  src, thumbnail, className, watermarkTitle,
}) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const [rotation, setRotation] = React.useState(45);

  React.useEffect(() => {
    setIsImageLoaded(false);
    const img = new window.Image();

    const imgLoad = () => {
      if (watermarkTitle) {
        const { height, width } = img;
        setRotation((Math.atan(height / width) * 180) / Math.PI);
      }
      setIsImageLoaded(true);
    };
    img.addEventListener('load', imgLoad, { once: true });
    img.src = src;

    return () => img.removeEventListener('load', imgLoad);
  }, [src, watermarkTitle]);

  const styleAttribute = React.useMemo(
    () => ({ '--tw-rotate': `-${rotation}deg`, '--tw-translate-x': '-50%', '--tw-translate-y': '-50%' } as React.CSSProperties),
    [rotation],
  );

  return (
    <>
      {!isImageLoaded && <MediaLoader displaySpinner={!thumbnail} />}
      {isImageLoaded && (
        <>
          {thumbnail && (
            <>
              <div
                className="bg-origin-padding bg-center bg-no-repeat absolute top-0 right-0 z-0 left-0 bottom-0 h-full bg-120"
                style={{ backgroundImage: `url("${src}")` }}
              />
              <div className="backdrop-blur-2xl absolute top-0 right-0 z-0 left-0 bottom-0 h-full bg-120" />
              <div
                className={classNames(
                  'bg-origin-padding bg-center bg-no-repeat bg-contain h-full z-10 relative',
                  className,
                )}
                style={{ backgroundImage: `url("${src}")` }}
              />
            </>
          )}
          {!thumbnail && (
            <div className={classNames({ relative: !!watermarkTitle })}>
              <img src={src} alt="" className="max-h-full max-w-full" />
              {watermarkTitle && (
                <span
                  className="text-nowrap absolute transform text-6xl top-1/2 left-1/2 font-bold uppercase text-white opacity-40 drop-shadow-watermark"
                  style={styleAttribute}
                >
                  {watermarkTitle}
                </span>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Image;
