/* eslint-disable react/style-prop-object */
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedNumber } from 'react-intl';

import { computeTax } from 'utils/priceUtils';

import { AbstractMedia } from 'types/AbstractMedia';

import { isPublicDevice } from 'modules/dealers/selectors';

import { FileList } from 'components/ui';
import { RepairOrder } from 'modules/selfServices/types/SelfService';

interface RepairOrderItemProps extends Omit<RepairOrder, 'adviceName'> {
  name: string;
  vat: number;
  currency: string;
  medias?: AbstractMedia[];
}
const RepairOrderItem: React.FC<RepairOrderItemProps> = ({
  name, price, discountedPrice, currency, vat, medias, coefficient,
}) => {
  const isPublic = useSelector(isPublicDevice);

  const showPrice = discountedPrice >= 0;
  const showDiscount = price !== discountedPrice;

  return (
    <div className="flex flex-col">
      <div className="flex justify-between gap-2">
        <div data-testid="repair-order-name" className="text-gray-90 font-bold break-words min-w-24 kiosk:text-3xl">
          {name}
        </div>
        {showPrice && (
          <div className="flex-none flex flex-col justify-center items-end">
            <p className="font-bold text-gray-90" data-testid="repair-order-discountedPrice">
              <FormattedNumber
                style="currency"
                currency={currency}
                currencyDisplay="narrowSymbol"
                value={computeTax(discountedPrice, vat, coefficient)}
              />
            </p>
            {showDiscount && (
              <p className="line-through italic font-medium text-sm" data-testid="repair-order-price">
                <FormattedNumber
                  style="currency"
                  currency={currency}
                  currencyDisplay="narrowSymbol"
                  value={computeTax(price, vat, coefficient)}
                />
              </p>
            )}
          </div>
        )}
      </div>
      {medias?.length > 0 && <FileList medias={medias} showDownload={!isPublic} />}
    </div>
  );
};

export default RepairOrderItem;
