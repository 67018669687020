import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSelector } from 'hooks';

import PageBaseProps from 'types/PageBase';

import { getKioskLocation, getLocationId } from 'modules/dealers/selectors';

import { Input, PageFooter } from 'components/ui';
import { KioskLocation } from 'modules/dealers/types/ContextResponse';

import shareboxLocker from '../../assets/images/sharebox.png';
import shareboxLocationId from '../../assets/images/sharebox_locationId.png';
import shareboxIndoorKiosk from '../../assets/images/shareboxIndoor.jpg';

const KioskLocationId: React.FC<PageBaseProps> = ({ onNext, onPrev }) => {
  const intl = useIntl();
  const kioskLocation = useSelector(getKioskLocation);

  const locationId = useSelector(getLocationId);

  return (
    <div className="main-content">
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-4 text-center w-full">
          <h1 className="text-gray-90">
            <FormattedMessage id="kioskLocation.title" defaultMessage="Kiosk location" />
          </h1>
          <p className="w-full text-gray-70 mb-6 mt-5 whitespace-pre-wrap break-words">
            <FormattedMessage
              id="kioskLocation.description"
              // eslint-disable-next-line max-len
              defaultMessage="Please present yourself in front of the kiosk with the following location ID before going to the next step."
            />
          </p>
          <Input
            disabled
            value={locationId}
            inputClassName="text-center"
            label={intl.formatMessage({ id: 'login.locationId', defaultMessage: 'Location ID' })}
          />
          <div className="relative">
            <div className="bg-input-bg rounded-lg kiosk:rounded-2xl p-3 w-full">
              <img src={kioskLocation === KioskLocation.INDOOR ? shareboxIndoorKiosk : shareboxLocker} alt="Sharebox locker" className="h-auto w-full rounded-lg" />
              {/* eslint-disable-next-line max-len */}
              <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rounded-full bg-white p-2 w-1/3 aspect-square">
                <img src={shareboxLocationId} alt="Sharebox locker" className="rounded-full size-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageFooter onNext={onNext} onPrev={onPrev} />
    </div>
  );
};

export default KioskLocationId;
