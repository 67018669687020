import {
  createListenerMiddleware, isAnyOf, ListenerEffect, ListenerEffectAPI, PayloadAction,
} from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { REHYDRATE } from 'redux-persist';
import { RehydrateAction } from 'redux-persist/es/types';

import { AppStartListening } from 'App/ListenerMiddleware';
import { AppDispatch, RootState } from 'App/Store';

import selfServicesApi from 'modules/selfServices/service';

import { addNotification } from 'modules/notifications/actions';
import socketSlice from 'modules/sockets/reducer';

import { getStepsConfig } from 'modules/form/selectors';
import { isCheckoutSelfService, isLocalKiosk } from 'modules/dealers/selectors';
import { getCurrentStep, isLastStep as isLastStepSelector } from 'modules/steps/selector';
import { getAdminToken, getSelectedSelfServiceId, getToken } from 'modules/auth/selectors';
import { getSelectedSelfService, getSelfServiceStatus } from 'modules/selfServices/selectors';
import { isPayingModalOpen as isPayingModalOpenSelector } from 'modules/onlinePayment/selectors';

import {
  isSocketResponseUpdate,
  KioskOperationUpdate,
  KioskPaymentUpdate,
  SocketResponseAction,
  SocketResponseUpdate,
} from 'modules/sockets/types/SocketResponse';

import { DoorsStatus } from 'modules/kiosk/types/KeysSafe';
import { NotificationType } from 'modules/notifications/types/Notification';
import SelfServiceStatus from 'modules/selfServices/types/SelfServiceStatus';
import { FinalInvoiceStatusesEnum, SelfServiceType } from 'modules/selfServices/types/SelfService';

import { locationChange } from 'modules/router/actions';
import { setDoorError, setDoorStatus, setIsKioskReady } from 'modules/kiosk/actions';

import authApi from 'modules/auth/service';
import { getSelfServiceSteps } from 'components/SelfServices/Steps';

import { getSearch, isHomePage } from 'modules/router/selectors';
import { closePayingModal, setPaymentStatus } from 'modules/onlinePayment/actions';
import PaymentStatus from 'modules/onlinePayment/types/PaymentStatus';

import * as actions from './actions';
import { POOL_INTERVAL } from './constants';
import { isInstructionModalDisplayed } from './selectors';

let socket: WebSocket;

const { WSOCKET_URL } = process.env;

const listenerMiddleware = createListenerMiddleware();

const startAppListening = listenerMiddleware.startListening as AppStartListening;

type KeepAliveEffect = ListenerEffect<ReturnType<typeof actions.initialized>, RootState, AppDispatch>;

export const isConnected = (wsocket: WebSocket): boolean => wsocket instanceof WebSocket
  && (wsocket.readyState === WebSocket.OPEN || wsocket.readyState === WebSocket.CONNECTING);

const createSocket = (path: string) => new Promise<WebSocket>((resolve, reject) => {
  const wsocket = new WebSocket(path);
  wsocket.addEventListener('open', () => resolve(wsocket), { once: true });
  wsocket.addEventListener('error', reject, { once: true });
});

const onReceiveMessage = (dispatch: AppDispatch) => async ({ data: stringifiedData }: MessageEvent) => {
  const socketResponse = JSON.parse(stringifiedData);
  if (isSocketResponseUpdate(socketResponse)) {
    if (socketResponse.action === SocketResponseAction.INTERVENTION_UPDATE) {
      dispatch(actions.selfServiceUpdated(socketResponse));
    }
    if (socketResponse.action === SocketResponseAction.KIOSK_PAYMENT_UPDATE) {
      dispatch(actions.kioskPaymentUpdated(socketResponse as unknown as KioskPaymentUpdate));
    }
    if (socketResponse.action === SocketResponseAction.KIOSK_OPERATION_UPDATE) {
      dispatch(actions.kioskOperationUpdated(socketResponse as unknown as KioskOperationUpdate));
    }
  }
};

export const handleOnError = (dispatch: AppDispatch) => (event: Event) => {
  dispatch(actions.socketError(event));
};

const closeSocket = () => {
  if (isConnected(socket)) {
    socket.close();
  }
};

export const startSocket = async (getState: () => RootState, dispatch: AppDispatch) => {
  const userToken = getToken(getState());
  const adminToken = getAdminToken(getState());
  const isHomePageLocation = isHomePage(getState());

  const token = userToken ?? adminToken;

  const isCheckout = isCheckoutSelfService(getState());
  const selfServiceStatus = getSelfServiceStatus(getState());

  if (!isHomePageLocation && token && !isConnected(socket)) {
    if (adminToken || isCheckout || (selfServiceStatus && selfServiceStatus !== SelfServiceStatus.ANSWERED)) {
      try {
        socket = await createSocket(`${WSOCKET_URL}?token=${token}`);

        socket.addEventListener('error', handleOnError(dispatch));
        socket.addEventListener('message', onReceiveMessage(dispatch));
        socket.addEventListener('close', () => startSocket(getState, dispatch));

        dispatch(actions.initialized());
      } catch (error) {
        dispatch(actions.creationFailure());
        console.error('Impossible to connect to socket with error');
      }
    }
  }
};

export const keepAlive: KeepAliveEffect = (action, api) => {
  if (isConnected(socket)) {
    api.dispatch(actions.sendMessage('ping'));
    setTimeout(() => keepAlive(action, api), POOL_INTERVAL);
  }
};

export const handleSelfServiceUpdate = async (
  { payload }: PayloadAction<SocketResponseUpdate>,
  { dispatch, getState, take }: ListenerEffectAPI<RootState, AppDispatch>,
) => {
  const state = getState();

  const isKiosk = isLocalKiosk(state);
  const config = getStepsConfig(state);
  const currentStep = getCurrentStep(state);
  const isLastStep = isLastStepSelector(state);
  const selfService = getSelectedSelfService(state);

  if (!isLastStep) {
    const isPaymentUpdated = payload.data.updatedPayment;
    const isPaymentInProgress = payload.data.paymentInProgress;
    const isCheckOutCompleted = payload.data.checkOutCompleted;
    const isFinalInvoiceUpdated = payload.data.updatedFinalInvoice;
    const shouldOpenLogoutModal = isFinalInvoiceUpdated || isCheckOutCompleted;
    const { type } = selfService;

    if (shouldOpenLogoutModal && type === SelfServiceType.CHECK_IN) {
      dispatch(socketSlice.actions.openLogoutModal());
    } else if (currentStep === 'VEHICLE_CHECK_QR_CODE') {
      if (payload.data.vehicleCheckStarted && isKiosk) {
        const urlParams = new URLSearchParams(state.router.location.search);
        urlParams.set('step', 'FINAL_INSTRUCTIONS');
        dispatch(push(`/checkin?${urlParams.toString()}`));
      }
    } else if (currentStep === 'FINAL_INVOICE') {
      if (isPaymentInProgress) {
        dispatch(setPaymentStatus(PaymentStatus.PENDING));
      } else {
        dispatch(setPaymentStatus(isPaymentUpdated ? PaymentStatus.SUCCESS : PaymentStatus.ERROR));

        const isPayingModalOpen = isPayingModalOpenSelector(state);
        if (isPayingModalOpen) {
          await take(closePayingModal.match);
        }
        // We refetch the self-service to get the updated final invoice
        dispatch(selfServicesApi.endpoints.getSelfServiceById.initiate({ id: selfService.id }, { forceRefetch: true }));
      }
    } else {
      const { isSuccess, data } = await dispatch(
        selfServicesApi.endpoints.getSelfServiceById.initiate({ id: selfService.id }, { forceRefetch: true }),
      );

      if (isSuccess) {
        // Order of steps matters!
        const selfServiceSteps = getSelfServiceSteps(data, config);

        const { key: stepKey } = selfServiceSteps.find(
          ({ data: stepData }) => stepData.socketUpdateFields?.some((field) => payload.data[field]),
        ) ?? {};

        // We redirect to the first step between the current step and the updated step
        const stepToRedirectTo = selfServiceSteps.find(
          ({ key }) => key === stepKey || key === currentStep,
        )?.key ?? selfServiceSteps[0].key;

        dispatch(socketSlice.actions.displayModal(stepToRedirectTo));
      } else {
        // Redirect to main page if self-service cannot be fetched
        const search = getSearch(getState());
        dispatch(push(`/${search}`));
      }
    }
  }
};

export const handleKioskPaymentUpdate = async (
  { payload }: PayloadAction<KioskPaymentUpdate>,
  { dispatch, getState }: ListenerEffectAPI<RootState, AppDispatch>,
) => {
  const state = getState();
  const isKiosk = isLocalKiosk(state);
  const selfServiceId = getSelectedSelfServiceId(state);
  const isInstructionOpen = isInstructionModalDisplayed(state);

  const { isSuccess: isSelfServiceSuccess, data: selfService } = await dispatch(
    selfServicesApi.endpoints.getSelfServiceById.initiate({ id: selfServiceId }, { forceRefetch: true }),
  );

  const { data: websocketData } = payload;

  // If all flags are set to false, it means that the payment is successful
  let [errorType] = Object.entries(websocketData).find(([, value]) => value === true) ?? [];
  // There is no distinction from client side between those 2 errors
  if (errorType === 'providerError' || errorType === 'hostUnavailable') {
    errorType = 'unknownError';
  }

  const isError = isInstructionOpen
   && selfService.finalInvoice.status !== FinalInvoiceStatusesEnum.PAID
   && (errorType || selfService.finalInvoice.aborted || selfService.finalInvoice.failed);

  if (isKiosk && isSelfServiceSuccess) {
    // Wait a bit before closing the modal to let the user see the success icon or the error message
    setTimeout(
      () => dispatch(socketSlice.actions.closeInstructionModal()),
      2000,
    );

    if (isError) {
      dispatch(
        addNotification({
          title: {
            id: `page.finalInvoice.payment.error.${errorType}.title`,
            defaultMessage: 'An error occur during the payment process',
          },
          description: {
            id: `page.finalInvoice.payment.error.${errorType}.description`,
            defaultMessage: 'Please try again later or contact us.',
          },
          type: NotificationType.ERROR,
        }),
      );
    }
  }
};

export const handleKioskOperationUpdate = async (
  { payload }: PayloadAction<KioskOperationUpdate>,
  { dispatch }: ListenerEffectAPI<RootState, AppDispatch>,
) => {
  const [key] = Object.entries(payload.data).find(([, value]) => value) ?? [];

  if (key === 'doorPrepared' || key === 'doorPrepareFailed') {
    // The preparation will be re-trigger during the opening process in case of failure.
    dispatch(setIsKioskReady(true));
  } else if (key === 'doorOpened' || key === 'doorClosed') {
    const status = key === 'doorOpened' ? DoorsStatus.OPENED : DoorsStatus.CLOSED;
    dispatch(setDoorStatus(status));
  } else if (key === 'doorOpenFailed' || key === 'doorCloseFailed') {
    dispatch(setDoorError(true));
    const status = key === 'doorOpenFailed' ? DoorsStatus.PENDING : DoorsStatus.OPENED;
    const origin = key === 'doorOpenFailed' ? DoorsStatus.OPENED : DoorsStatus.CLOSED;
    dispatch(setDoorStatus(status, { origin }));
  }
};

function isRehydrateAction(action: PayloadAction<RehydrateAction>): action is PayloadAction<RehydrateAction> {
  return action.type === REHYDRATE;
}

// Create and initialized socket
startAppListening({
  matcher: isAnyOf(
    isRehydrateAction,
    selfServicesApi.endpoints.getSelfServiceById.matchFulfilled,
    authApi.endpoints.loginAdmin.matchFulfilled,
  ),
  effect: async (_, { dispatch, getState }) => startSocket(getState, dispatch),
});

// When a message is sent on the socket
startAppListening({
  actionCreator: actions.sendMessage,
  effect: ({ payload }) => {
    if (isConnected(socket)) {
      socket.send(payload);
    }
  },
});

// After initialized the socket
startAppListening({
  actionCreator: actions.initialized,
  effect: keepAlive,
});

// Handle selfServiceUpdated action
startAppListening({
  actionCreator: actions.selfServiceUpdated,
  effect: handleSelfServiceUpdate,
});

// Handle kioskPaymentUpdated action
startAppListening({
  actionCreator: actions.kioskPaymentUpdated,
  effect: handleKioskPaymentUpdate,
});

// Handle kioskOperationUpdated action
startAppListening({
  actionCreator: actions.kioskOperationUpdated,
  effect: handleKioskOperationUpdate,
});

// Close socket
startAppListening({
  actionCreator: socketSlice.actions.closeSocket,
  effect: closeSocket,
});

startAppListening({
  matcher: locationChange.match,
  effect: (_, { getState }) => {
    if (isHomePage(getState())) {
      closeSocket();
    }
  },
});

export default listenerMiddleware;
