import React from 'react';
import { Action } from 'history';
import { useLocation } from 'react-router-dom';

import PageBaseProps from 'types/PageBase';

import { logout } from 'modules/auth/actions';
import useSteps, { Step } from 'hooks/useSteps';

import PageHeader from '../PageHeader';
import { useDispatch } from '../../../hooks';
import usePrompt, { ShouldPromptType } from '../../../hooks/usePrompt';

import 'flag-icons/css/flag-icons.min.css';

interface UIStepData {
  hideHeader?: boolean;
  hidePrevButton?: boolean;
  hideStepIndicator?: boolean;
  preventBrowserNavigation?: boolean;
}

export type UIStep<T = object> = Step<UIStepData & T, PageBaseProps>;

interface StepsProps {
  steps: UIStep[];
  step?: UIStep | string;
  onChange?: (step: UIStep) => void;
  promptMessage?: string;
}

const Steps: React.FC<StepsProps> = ({
  step: initialStep, steps, onChange, promptMessage,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const {
    currentStep, next, previous,
  } = useSteps({
    initialStep, steps, onChange,
  });

  const renderStep = React.useCallback(() => {
    if ('render' in currentStep) {
      return currentStep.render({ onNext: next, onPrev: previous });
    }

    const penultimateStepKey = steps[steps.length - 2]?.key;
    const isPenultimate = currentStep.key === penultimateStepKey;
    const { component: Component } = currentStep;
    return <Component onNext={next} onPrev={previous} isPenultimate={isPenultimate} />;
  }, [currentStep, steps, next, previous]);

  React.useLayoutEffect(() => window.scrollTo(0, 0), [currentStep.key]);

  const visibleSteps = steps.filter(({ data }) => !data?.hideHeader);
  const currentIndex = visibleSteps.findIndex(({ key }) => key === currentStep.key);

  const callback = () => dispatch(logout());
  const shouldBlock = Boolean(currentStep.data?.preventBrowserNavigation);

  // Prompt when:
  // - The pathname of the following location is different ex: from checkin to login
  // - The current step prevent back and the following location step is the previous step ex: from step final instruction to key
  // - The current step prevent back and the flowing step doesn't exist: from step checkin/?step=customerInfo to login/?step=verify
  const shouldPrompt: ShouldPromptType = (location, action) => {
    if (location.pathname === pathname) {
      const urlParams = new URLSearchParams(location.search);
      const nextLocationStep = steps.find(({ key }) => key === urlParams.get('step'));
      return action === Action.Pop
          && currentStep.data?.preventBrowserNavigation
          && (!nextLocationStep || nextLocationStep.next === currentStep.key);
    }

    return action === Action.Pop;
  };

  usePrompt(promptMessage, shouldPrompt, shouldBlock, callback);

  return (
    <>
      {!currentStep.data?.hideHeader && (
        <PageHeader
          onPrev={previous}
          currentIndex={currentIndex}
          length={visibleSteps.length}
          hideSteps={currentStep.data?.hideStepIndicator}
          hidePrevButton={currentStep.data?.hidePrevButton}
        />
      )}
      {renderStep()}
    </>
  );
};

export default Steps;
