import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames, { Argument } from 'classnames';

import { DoorsStatus } from 'modules/kiosk/types/KeysSafe';
import {
  isIdle as isIdleSelector,
  hasError as hasErrorSelector,
  isMoving as isMovingSelector,
  isClosingOrClosed as isClosingOrClosedSelector,
} from 'modules/kiosk/selectors';
import { isKioskEmergencyMode } from 'modules/dealers/selectors';

import useUnmountAnimation from 'hooks/useUnmountAnimation';

interface KeysManagementModalProps {
  loaderClassName?: Argument;
  label?: React.ReactNode;
}

const KeysManagementModal: React.FC<React.PropsWithChildren<KeysManagementModalProps>> = ({
  loaderClassName, label, children,
}) => {
  const isIdle = useSelector(isIdleSelector);
  const isMoving = useSelector(isMovingSelector);
  const hasError = useSelector(hasErrorSelector);
  const isClosingOrClosed = useSelector(isClosingOrClosedSelector);
  const hasKioskDoorsError = useSelector(isKioskEmergencyMode);

  const {
    isVisible: isLoading,
    animation: loaderAnimation,
    handleAnimationEnd: handleLoaderAnimationEnd,
  } = useUnmountAnimation({ trigger: isMoving, animation: 'animate-shadow-leave', disabled: !hasKioskDoorsError });

  const {
    isVisible: displayModal,
    animation: modalAnimation,
    handleAnimationEnd: handleModalAnimationEnd,
  } = useUnmountAnimation({ trigger: !isIdle || hasError, animation: 'animate-shorter-shadow-leave', disabled: !hasKioskDoorsError });

  // because the component is not unmounted directly when doorStatus === CLOSED as there is the animation time.
  const doorStatus = isClosingOrClosed ? DoorsStatus.CLOSING : DoorsStatus.OPENING;
  const renderContent = !isLoading && !isIdle;

  return (
    displayModal && (
      <div
        data-testid="key-safe-modal"
        onAnimationEnd={handleModalAnimationEnd}
        className={classNames('flex flex-col items-center', modalAnimation, {
          'justify-center absolute inset-y-0 w-svw z-50 h-svh bg-gradient-to-t from-emergency-gradient-from to-emergency-gradient-to': hasKioskDoorsError,
          'flex-grow bg-secondary z-0 rounded-t-6xl pt-32 px-32 key-modal-transition--slide-up key-modal-transition--slide-up-enter': !hasKioskDoorsError,
        })}
      >
        {isLoading && (
          <div
            onAnimationEnd={handleLoaderAnimationEnd}
            className={classNames('flex flex-col gap-52 text-6xl', loaderAnimation, { 'gap-32': hasKioskDoorsError })}
          >
            <div className="flex flex-col items-center">
              <h1 className={classNames({ 'text-white': hasKioskDoorsError })} id="keys-modal-loading">
                <FormattedMessage
                  id={`key.${doorStatus.toLowerCase()}`}
                  defaultMessage={doorStatus === DoorsStatus.CLOSING ? 'Closing' : 'Opening'}
                />
              </h1>
              {label}
            </div>
            <span className={classNames('loader', loaderClassName)} />
          </div>
        )}
        {renderContent && children}
      </div>
    )
  );
};

export default KeysManagementModal;
