import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from 'hooks';
import { Button, Input, Modal } from 'components/ui';

import { logout } from 'modules/auth/actions';
import { useGetContextQuery } from 'modules/dealers/service';
import { useLoginAdminMutation } from 'modules/auth/service';
import { ScheduleTypeEnum } from 'modules/auth/types/InitialState';
import { getQRCodeToken, getScheduledTime } from 'modules/auth/selectors';

import InactivityProgressBar from './InactivityProgressBar';

const InactivityModal: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const ref = React.useRef<HTMLInputElement>();

  const qrCodeToken = useSelector(getQRCodeToken);
  const logoutDate = useSelector((state) => getScheduledTime(state, ScheduleTypeEnum.LOGOUT));

  const [pinCode, setPinCode] = React.useState('');

  const { data: contextData } = useGetContextQuery();
  const [login, { isLoading, isError }] = useLoginAdminMutation();

  const error = isError
    ? intl.formatMessage({
      id: 'login.error.description.MFA_WRONG_CODE',
      defaultMessage: 'Wrong code, please try again',
    })
    : undefined;

  const handleOnChange = React.useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = target;
      if (value.length <= 6 && value.match(/^[0-9]*$/)) {
        setPinCode(value);
      }
    },
    [],
  );

  const handleConfirm = React.useCallback(() => {
    login({ kioskId: contextData?.kioskId, pinCode, qrCodeToken });
  }, [login, contextData, pinCode, qrCodeToken]);

  const handleKeyDown = React.useCallback(({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter' && pinCode.match(/^[0-9]{6}$/)) {
      handleConfirm();
    }
  }, [handleConfirm, pinCode]);

  const handleTimerEnded = React.useCallback(() => {
    dispatch(logout(undefined, { asAdmin: true }));
  }, [dispatch]);

  return (
    <Modal
      open
      focusRef={ref}
      className="modal-large"
      title={(
        <div className="mb-16">
          <span className="text-center">
            <FormattedMessage
              id="inactivity.modal.title"
              defaultMessage="Are you still there?"
              tagName="h2"
            />
          </span>
        </div>
      )}
    >
      <div className="w-full px-[20%]">
        <InactivityProgressBar date={logoutDate} stopped={isLoading} onEnded={handleTimerEnded} />
      </div>
      <div className="flex flex-col items-center gap-4 mt-20">
        <p className="text-center"><FormattedMessage id="inactivity.modal.content" defaultMessage="Confirm your identity by entering your PIN" /></p>
        <Input
          center
          ref={ref}
          error={error}
          type="number"
          value={pinCode}
          autoCorrect="off"
          spellCheck="false"
          className="w-full"
          inputMode="numeric"
          data-testid="code-input"
          onChange={handleOnChange}
          onKeyDown={handleKeyDown}
          inputClassName="adminPinCode"
        />
      </div>
      <div className="flex flex-col items-center gap-4 mt-5">
        <Button
          loading={isLoading}
          testId="modal-confirm"
          onClick={handleConfirm}
          className="w-full mt-5"
        >
          <FormattedMessage id="confirm.title" defaultMessage="Confirm" />
        </Button>
      </div>
    </Modal>
  );
};

export default InactivityModal;
