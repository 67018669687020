import React from 'react';
import { push } from 'redux-first-history';
import { FormattedMessage } from 'react-intl';

import { useDispatch } from 'hooks';

import { getUrlParam } from 'utils/urlUtils';

import Confirm from '../ui/Confirm';
import { ButtonType } from '../ui/Button';

interface MobilityNotPreparedModalProps {
  onClose: () => void;
}

const MobilityNotPreparedModal:React.FC<MobilityNotPreparedModalProps> = ({ onClose }) => {
  const dispatch = useDispatch();
  const handleOnConfirm = () => {
    onClose();
  };

  const handleOnCancel = React.useCallback(() => {
    const pathToken = getUrlParam('token');
    dispatch(push(`/?token=${pathToken}`));
  }, [dispatch]);

  return (
    <Confirm
      open
      focusCancel
      hideCloseButton
      onCancel={handleOnCancel}
      onConfirm={handleOnConfirm}
      cancel={<FormattedMessage id="mobility.warning.logout" defaultMessage="No, log out" />}
      confirm={<FormattedMessage id="continue.yes" defaultMessage="Yes, continue" />}
      confirmProps={{ type: ButtonType.ERROR }}
      title={(
        <h2 className="flex justify-center items-center p-4">
          <FormattedMessage id="warning" defaultMessage="Warning" />
        </h2>
      )}
      question={(
        <FormattedMessage
          id="mobility.warning.description"
          defaultMessage="It seems that the keys of your courtesy vehicle are not ready yet. Do you want to check in anyway? You will not receive the keys of the courtesy vehicle but can deposit yours."
          tagName="p"
        />
      )}
    />
  );
};

export default MobilityNotPreparedModal;
