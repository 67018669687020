import React from 'react';
import classNames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';

import CrossIcon from 'assets/icons/cross.svg';
import { shouldDisplayTransitions } from 'modules/app/selectors';

import Button, { ButtonType } from './Button';
import { useSelector } from '../../hooks';

const getOptionClassName = ({ selected }: { selected: boolean }) => classNames(
  'relative cursor-pointer select-none py-2 px-4 kiosk:py-4',
  { 'bg-secondary text-dark-grey': selected, 'text-gray-7': !selected },
);

interface SelectInputProps<T> {
  data: T;
  label: string;
  multiple?: boolean;
  'data-testid'?: string;
  value: keyof T | Array<keyof T>;
  inputOptionBottom?: boolean;
  onChange: (value: keyof T | Array<keyof T>) => void;
}

const transitions = {
  leaveTo: 'opacity-0',
  leaveFrom: 'opacity-100',
  leave: 'transition ease-in duration-100',
};

const SelectInput = <T extends Record<string, React.ReactNode>>({
  label, value, onChange, data, 'data-testid': dataTestId, multiple, inputOptionBottom,
}: SelectInputProps<T>) => {
  const displayTransitions = useSelector(shouldDisplayTransitions);

  const handleRemove = React.useCallback((item: keyof T) => (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onChange((value as Array<keyof T>).filter((i) => i !== item));
  }, [onChange, value]);

  const hasValue = multiple ? (value as Array<keyof T>).length > 0 : Boolean(value);

  return (
    <Listbox value={value} onChange={onChange} multiple={multiple}>
      <div className="relative">
        <div className="group bg-input-bg rounded-lg kiosk:rounded-2xl">
          <Listbox.Button
            className="text-left input peer focus:shadow-input-focus min-h-[56px] kiosk:min-h-[120px]"
            data-testid={dataTestId}
          >
            <>
              {multiple && (
                <div className="flex flex-row w-full flex-wrap mt-1">
                  {(value as Array<keyof T>).map((item: string) => (
                    <div
                      key={item}
                      className="flex flex-row bg-primary text-dark-grey border-2 rounded-lg kiosk:rounded-2xl px-2 py-1 kiosk:p-4 mr-1 mb-1"
                    >
                      {data[item]}
                      <Button
                        testId="remove-item"
                        type={ButtonType.LIGHT}
                        onClick={handleRemove(item)}
                        className="!p-0 ml-4 kiosk:p-0"
                      >
                        <CrossIcon className="w-2 kiosk:w-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              )}
              {!multiple && data[value as keyof T]}
              <span
                className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 kiosk:pr-4"
              >
                <ChevronUpDownIcon
                  className="size-5 kiosk:size-10 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </>
          </Listbox.Button>
          <span className={classNames('input-label pointer-events-none', {
            'label-no-value peer-focus:translate-y-0 kiosk:peer-focus:translate-y-0': !hasValue,
          })}
          >
            {label}
          </span>
        </div>
        <Transition
          as={React.Fragment}
          {...(displayTransitions ? transitions : {})}
        >
          <Listbox.Options
            className={classNames(
              // eslint-disable-next-line max-len
              'absolute z-40 !mt-1 right-0 left-0 max-h-1/2-screen kioskSharebox:max-h-96 overflow-auto rounded-md shadow-sm bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
              { 'bottom-full': inputOptionBottom },
            )}
          >
            {Object.entries(data).map(([key, option]) => (
              <Listbox.Option
                key={key}
                value={key}
                data-testid={`option-${key}`}
                className={getOptionClassName}
              >
                {({ selected }) => (
                  <span className={classNames('flex truncate text-sm kiosk:text-2xl kioskSharebox:text-xl', {
                    'font-medium': selected,
                    'font-normal': !selected,
                  })}
                  >
                    {multiple && (
                      <div className="flex items-center pl-2 kiosk:pl-6 pr-2">
                        <div className={classNames(
                          'flex size-4 kiosk:size-6 rounded-xl justify-center items-center',
                          { 'bg-tertiary': selected, 'border-border-bg border-2': !selected },
                        )}
                        >
                          {selected && <div className="bg-white size-1.5 kiosk:size-2 rounded-full" />}
                        </div>
                      </div>
                    )}
                    {option}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default SelectInput;
